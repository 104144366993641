import { Link } from "react-router-dom";
import "../styles/pageNotFound.css";

const PageNotFound = () => {
  return (
    <div className="pnf-container">
      <h1 className="pnfh1">404</h1>
      <p className="pnfp ">Oops! Something is wrong.</p>
      <Link className="buttonPnf" to={"/"}>
        Go back to Home Page, is better.
      </Link>
    </div>
  );
};

export default PageNotFound;
