import { createContext, useState } from "react";

const SidebarIndex = createContext();

export const Provider = ({ children }) => {
  const [ index, setIndex ] = useState(() => {
    const storedIndex = localStorage.getItem('sideBarIndex');
    return storedIndex ? parseInt(storedIndex) : 0;
  });

  const setSideBarIndex = (index) => {
    setIndex(index);
    localStorage.setItem('sideBarIndex', index.toString());
  };

  return (
    <SidebarIndex.Provider value={{ index, setSideBarIndex }}>
      {children}
    </SidebarIndex.Provider>
  );
};


export default SidebarIndex;