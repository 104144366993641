import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { auth, db } from "../../config/firebaseSetup";
import {
  collection,
  doc,
  onSnapshot,
  updateDoc,
  query,
  orderBy,
  addDoc,
  getDocs,
  arrayUnion,
  where,
  writeBatch,
} from "firebase/firestore";
import { toast } from "react-toastify";
import InlineLoader from "../InlineLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sendIcon from "../../assets/icons/sendIcon.svg";

const ConversationView = ({ conversationId }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newMessage, setNewMessage] = useState("");
  const [availableAdmins, setAvailableAdmins] = useState([]);
  const [assignedAdmins, setAssignedAdmins] = useState([]);
  const messagesContainerRef = useRef(null);

  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchAdmins = async () => {
      const q = query(collection(db, "admins"));
      const querySnapShot = await getDocs(q);
      setAvailableAdmins(
        querySnapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };
    fetchAdmins();
  }, []);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const assignRandomAdmin = async () => {
    if (assignedAdmins.length > 0) return;
    const randomIndex = Math.floor(Math.random() * availableAdmins.length);
    const randomAdmin = availableAdmins[randomIndex];
    const conversationRef = doc(db, "conversations", conversationId);
    await updateDoc(conversationRef, {
      assignedAdmins: arrayUnion(randomAdmin.id),
    });
  };

  useEffect(() => {
    if (conversationId) {
      setLoading(true);
      const fetchConversationDetails = async () => {
        try {
          const docRef = doc(db, "conversations", conversationId);
          const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
              // setMessages(doc.data().messages || []);
              setAssignedAdmins(doc.data().assignedAdmins || []);
            }
          });
          return unsubscribe;
        } catch (error) {
          console.error("Error fetching conversation details:", error);
          setAssignedAdmins([]);
        }
      };

      const messagesRef = collection(
        db,
        "conversations",
        conversationId,
        "messages"
      );
      const q = query(messagesRef, orderBy("timestamp", "asc"));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        try {
          const fetchedMessages = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMessages(fetchedMessages);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching messages:", error);
          setMessages([]);
          setLoading(false);
        }
      });

      return () => {
        unsubscribe();
        fetchConversationDetails();
      };
    }
  }, [conversationId]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    await assignRandomAdmin();

    const messagesRef = collection(
      db,
      "conversations",
      conversationId,
      "messages"
    );
    const unreadMessagesQuery = query(
      messagesRef,
      where("read", "==", false),
      where("senderId", "!=", currentUser.uid)
    );

    const batch = writeBatch(db);

    try {
      const querySnapshot = await getDocs(unreadMessagesQuery);
      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, { read: true });
      });

      const newMessageRef = doc(messagesRef);
      const newMessageData = {
        senderId: currentUser.uid,
        message: newMessage,
        timestamp: Date.now(),
        read: false,
      };
      batch.set(newMessageRef, newMessageData);

      // Update the mostRecentMessage field in the conversations document
      const conversationRef = doc(db, "conversations", conversationId);
      batch.update(conversationRef, {
        mostRecentMessage: newMessageData,
        lastUpdated: Date.now(),
      });

      await batch.commit();
      setNewMessage("");
    } catch (error) {
      toast.error("Unable to send message right now");
      console.error("Unable to send message:", error);
    }
  };

  return (
    <div className="conversationViewContainer">
      <div className="messagesContainer" ref={messagesContainerRef}>
        {loading ? (
          <InlineLoader />
        ) : (
          messages.map((message) => (
            <div key={message.id}>
              <p
                className={`${
                  message.senderId === currentUser.uid
                    ? "userMessage"
                    : "adminMessage"
                }`}
              >
                {message.message}
              </p>
            </div>
          ))
        )}
      </div>
      <div className="sendMessageInput ">
        <input
          type="text"
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button onClick={handleSendMessage}>
          <img src={sendIcon} alt="" />
        </button>
      </div>
    </div>
  );
};

export default ConversationView;
