import { Link, useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../components/InputField";
import lockIcon from "../assets/icons/lock.svg";
import emailIcon from "../assets/icons/email.svg";
import facebookIcon from "../assets/icons/facebook.svg";
import googleIcon from "../assets/icons/google.svg";
import amazonIcon from "../assets/icons/amazon.svg";
import cancelIcon from "../assets/icons/cancel.svg";
import Button from "../components/Button";
import AuthBtn from "../components/AuthBtn";
import "../styles/loginPageStyle.css";
import { emailValidation, passwordValidation } from "../utils/inputValidations";
import {
  auth,
  db,
  mySignInWithEmailAndPassword,
  signInWithGoogle,
} from "../config/firebaseSetup";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useUser } from "../context/UserContext";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import Loading from "../components/Loader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";
import fetchAndSendEmailTemplate from "../utils/emailSend";
import fetchAndSendSMSTemplate from "../utils/smsSend";
import { firstNames, lastNames } from "../utils/names";

const LoginPage = () => {
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const methods = useForm();
  const location = useLocation();
  const { login, user, logout, closeAuthModalHandler, showSignUpModalHandler } =
    useUser();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is logged in
        // console.log("user is already login:", user);
        login(user);
      } else {
        // No user is logged in
        // console.log("no user is login");
      }
    });
  }, []);

  // const generateName = () => {
  //   const newName = `${
  //     firstNames[Math.floor(Math.random() * firstNames.length)]
  //   } ${lastNames[Math.floor(Math.random() * lastNames.length)]}`;
  //   methods.setValue("username", newName);
  //   return newName;
  // };

  // useEffect(() => {
  //   generateName();
  // }, []);

  const submitHandler = methods.handleSubmit(async (formData) => {
    try {
      setShowLoader(true);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      const user = userCredential.user;

      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));

        if (userDoc.exists()) {
          const userData = userDoc.data();
          localStorage.setItem("loginUser", JSON.stringify(user));
          login(user);
          toast.success("Login Success");
          closeAuthModalHandler();

          console.log(userData.notificationSettings.loginNotifications);
          if (userData && userData.notificationSettings.loginNotifications) {
            fetchAndSendEmailTemplate(
              "For Login",
              user,
              "https://stripe-server-production-3c2c.up.railway.app/send-email"
            );
          }

          if (location.pathname.startsWith("/auction-item")) {
            navigate(0);
          }
          // if (userData.phoneNotifications && userData.phoneNumber) {
          //   fetchAndSendSMSTemplate(
          //     "For Login",
          //     user,
          //     "http://localhost:3002/sms-send"
          //   );
          // }
        } else {
          toast.error("User not found");
          auth.signOut();
          logout();
        }
      } else {
        // Handle login failure
        toast.error("Error logging in: Invalid user credentials");
      }
    } catch (err) {
      // Handle specific errors
      switch (err.code) {
        case "auth/user-not-found":
          toast.error("No user found with this email", { appearance: "error" });
          break;
        case "auth/wrong-password":
          toast.error("Invalid password", { appearance: "error" });
          break;
        case "auth/network-request-failed":
          toast.error("Network request failed. Please try again", {
            appearance: "error",
          });
          break;
        case "auth/invalid-credential":
          toast.error("Invalid credential", { appearance: "error" });
          break;
        default:
          console.log(err.code);
          toast.error("Error during login. Please try again", {
            appearance: "error",
          });
      }
    } finally {
      setShowLoader(false);
    }
  });

  const loginWithFacebook = () => {
    console.log("loginWithFacebook");
  };

  const loginWithGoogle = async () => {
    // const username = generateName();
    setShowLoader(true);
    await signInWithGoogle(closeAuthModalHandler, navigate);
    setShowLoader(false);
    if (location.pathname.startsWith("/auction-item")) {
      navigate(0);
    }
  };

  const loginWithAmazon = () => {
    console.log("loginwithAmazon");
  };

  const { t } = useTranslation("text");

  return (
    <>
      <h2 className="auth-heading">BIDANDGO</h2>
      <div className="login-page">
        <div className="login-page-header-container">
          <div className="login-page-head">
            <h2>{t("haveAccHead")}</h2>
            <p>
              {t("haveAccDes")}
              <button onClick={showSignUpModalHandler}>
                {t("haveAccBtn")}
              </button>
            </p>
          </div>
          <div onClick={closeAuthModalHandler}>
            <img src={cancelIcon} alt="" />
          </div>
        </div>
        <FormProvider {...methods}>
          <form
            action=""
            onSubmit={(e) => e.preventDefault()}
            autoComplete="off"
          >
            <InputField
              type={"email"}
              placeholder={"Email"}
              name={"email"}
              id={"loginEmail"}
              icon={emailIcon}
              validationRules={emailValidation}
              label={"Email"}
            />
            <InputField
              type={"password"}
              placeholder={"Password"}
              name={"password"}
              id={"password"}
              icon={lockIcon}
              label={"Password"}
              validationRules={passwordValidation}
            />
            <Button className="login-page-btn" onClick={submitHandler}>
              {t("haveAccHead")}
            </Button>
            <div
              onClick={() => {
                closeAuthModalHandler();
                navigate("/reset-password");
              }}
              className="forget-pass"
            >
              Forget Password
            </div>
            <div className="login-page-divider">
              <div className="div1"></div>
              <div>or</div>
              <div className="div2"></div>
            </div>
          </form>
        </FormProvider>
        <div className="auth-btns-container">
          <AuthBtn
            className={"login-page-auth-btn login-page-auth-btn-f"}
            onClick={loginWithFacebook}
          >
            <img src={facebookIcon} alt="icon" />
            {/* <p style={{ marginBottom: 0 }}>{t("facebook")}</p> */}
          </AuthBtn>
          <AuthBtn
            className={"login-page-auth-btn login-page-auth-btn-g"}
            onClick={loginWithGoogle}
          >
            <img src={googleIcon} alt="icon" />
            {/* <p style={{ marginBottom: 0 }}>{t("google")}</p> */}
          </AuthBtn>
          <AuthBtn
            className={"login-page-auth-btn login-page-auth-btn-a"}
            onClick={loginWithAmazon}
          >
            <img src={amazonIcon} alt="icon" />
            {/* <p style={{ marginBottom: 0 }}>{t("amazon")}</p> */}
          </AuthBtn>
        </div>
        {showLoader && <Loading />}
      </div>
    </>
  );
};

export default LoginPage;
