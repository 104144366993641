import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import TagAuctionsPage from "../../pages/TagAuctionsPage";
import transition from "../../utils/transition";

const Tag = () => {
  return (
    <div>
      {/* <Header /> */}
      <TagAuctionsPage />
      <Newsletter />
      <Footer color={"black"} />
    </div>
  );
};

export default transition(Tag);
