import { useEffect } from "react";
import { motion, useAnimation, useScroll, useTransform } from "framer-motion";
import Button from "./Button";
import "../styles/heroStyles.css";
import { useNavigate } from "react-router-dom";
import bgVideo from "../assets/images/bgVideo.mp4";

const Hero = () => {
  const navigate = useNavigate();
  const controls = useAnimation();
  const { scrollYProgress } = useScroll();

  const heroContainerAnimation = useTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    [0, -10, 20, -30, 40, -50, 60, -70, 80, -90, 100]
  );

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: { duration: 0.4 },
    });
  }, [controls]);

  return (
    <div className="hero-container">
      <video autoPlay muted loop id="myVideo">
        <source src={bgVideo} type="video/mp4" />
      </video>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={controls}
        style={{ y: heroContainerAnimation }}
        className="hero-content"
      >
        <h1>Elevate Your Bids with Seamless Online Auctions</h1>
        <p>Join the Thrill of Competitive Bidding Today</p>
        <Button onClick={() => navigate("/auctions")}>Start Bidding</Button>
      </motion.div>
    </div>
  );
};

export default Hero;
