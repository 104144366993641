import Button from "./Button";
import "../styles/resetFormStyles.css";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../config/firebaseSetup";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

const ResetPasswordForm = () => {
  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const resetPassword = handleSubmit((formData) => {
    sendPasswordResetEmail(auth, formData.email)
      .then(() => {
        toast.success("Please check your email to reset your password");
        methods.reset();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error("Error sending email to your email");
      });
  });

  console.log(errors);

  return (
    <div className="reset-pass-con forgot-password">
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => e.preventDefault()}
          className="auth-form forgot-password"
        >
          <div className="row labels">
            <h2>Forgot your password?</h2>
            <h3>
              Don't worry. Just enter your email address below and we'll send
              you an email to reset password
            </h3>
          </div>

          <div className="row email">
            <input
              type="email"
              name="email"
              required
              autoFocus
              placeholder="Email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid email address",
                },
              })}
            />
          </div>
          {errors?.email?.message}

          <div className="row submit">
            <Button className="button big blue" onClick={resetPassword}>
              Send Email
            </Button>
          </div>
        </form>
      </FormProvider>

      <span className="link login">
        Once you reset your password please try to login again
      </span>
    </div>
  );
};

export default ResetPasswordForm;
