import { useContext } from "react";
import { Context as AuctionContext } from "../../context/AuctionContext";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import AuctionItemDetailPage from "../../pages/AuctionItemDetailPage";
import Loading from "../../components/Loader";
import transition from "../../utils/transition";

const AuctionDetail = () => {
  const { state } = useContext(AuctionContext);

  // if (!state.allAuctions) {
  //   return <Loading />;
  // }

  return (
    <div>
      {/* <Header /> */}
      <AuctionItemDetailPage />
      <Newsletter />
      <Footer color={"black"} />
    </div>
  );
};

export default transition(AuctionDetail);
