import InputField from "../InputField";
import {
  addressValidation,
  cityValidation,
  provinceValidation,
  postalCodeValidation,
  emailValidation,
  usernameValidation,
} from "../../utils/inputValidations";
import "../../styles/addressCardStyles.css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const BillingCard = ({ style = {} }) => {
  const { t } = useTranslation("billingAddress");

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.type === "number") {
        e.preventDefault();
      }
    };

    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div style={style} className="address-card">
      <h3>{t("title")}</h3>
      <div>
        <div className="address-card-two-fields">
          <InputField
            type={"text"}
            placeholder={""}
            name={"billToAddress"}
            id={"billTo"}
            validationRules={{}}
            label={"Bill To"}
          />
          <InputField
            type={"email"}
            placeholder={""}
            name={"email"}
            id={"email"}
            validationRules={emailValidation}
            label={"Email"}
            disabled={true}
          />
        </div>
        <div className="address-card-two-fields">
          <InputField
            type={"text"}
            placeholder={"Name"}
            name={"billingInfoName"}
            id={"billingInfoName"}
            validationRules={usernameValidation}
            label={"Name"}
          />
          <InputField
            type={"text"}
            placeholder={"Surname"}
            name={"billingInfoSurname"}
            id={"billingInfoSurname"}
            validationRules={usernameValidation}
            label={"Surname"}
            // disabled={true}
          />
        </div>
        <InputField
          type={"text"}
          placeholder={""}
          name={"addressBillTo"}
          id={"address"}
          validationRules={addressValidation}
          label={"Address"}
        />
        <InputField
          type={"text"}
          placeholder={""}
          name={"cityBillTo"}
          id={"city"}
          validationRules={cityValidation}
          label={"City"}
        />
        <div className="address-card-three-fields">
          <InputField
            type={"text"}
            placeholder={""}
            name={"countryBillTo"}
            id={"country"}
            validationRules={{}}
            label={"Country"}
          />
          <InputField
            type={"text"}
            placeholder={""}
            name={"state-provinceBillTo"}
            id={"apartment"}
            validationRules={provinceValidation}
            label={"State Province"}
          />
          <div className="spanmore">
            <InputField
              type={"text"}
              placeholder={""}
              name={"postal-codeBillTo"}
              id={"postalCode"}
              validationRules={postalCodeValidation}
              label={"Postal Code"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingCard;
