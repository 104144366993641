import React from "react";
import {
  creditCardValidation,
  cvvValidation,
  expirationDateValidation,
} from "../../utils/inputValidations";
import InputField from "../InputField";
import "../../styles/paymentCardStyles.css";
import { withTranslation } from "react-i18next";

// const PaymentCard = ({ style = {}, showinfo }) => {
//   const { t } = useTranslation("paymentInformation");

//   return (
//     <div style={style} className="payment-card">
//       <h3>{t("title")}</h3>
//       <div className="payment-card-fields">
//         <div className="card-num">
//           <InputField
//             type={"number"}
//             placeholder={""}
//             name={"card-number"}
//             id={"card-number"}
//             validationRules={creditCardValidation}
//             label={t("cardNumber")}
//           />
//         </div>
//         <InputField
//           type={"text"}
//           placeholder={""}
//           name={"exp-date"}
//           id={"exp-date"}
//           validationRules={expirationDateValidation}
//           label={t("expirationDate")}
//         />
//         <InputField
//           type={"text"}
//           placeholder={""}
//           name={"cvv-cvd"}
//           id={"cvv-cvd"}
//           validationRules={cvvValidation}
//           label={t("cvvCvd")}
//         />
//       </div>
//       {showinfo && (
//         <div className="bid-now-info">
//           <p>{t("infoMessage")}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PaymentCard;

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH = new Date().getMonth();
let MONTHS = {},
  YEARS = [CURRENT_YEAR];
for (let i = 1; i <= 12; i++) {
  MONTHS[i] = i.toString().length === 1 ? `0${i}` : i.toString();
  YEARS.push(YEARS[0] + i);
}

class PaymentCard extends React.Component {
  state = {
    cardNumber: "",
    cardName: "",
    cardMonth: 0,
    cardYear: 0,
    cardCvv: "",
    cardType: "visa",
    toggleMonth: true,
    toggleYear: true,
  };

  // const { t } = useTranslation("paymentInformation");

  handleChange = (event, type) => {
    let { value } = event.target;

    if (type === "cardNumber") {
      value = value.replace(/ /gi, "");
      if (isNaN(value)) {
        return;
      } else {
        const cardType = this.getCardType(value);
        this.setState({ [type]: value, cardType });
      }
    } else if (type === "cardName") {
      var regName = /^[a-zA-Z\s]*$/;
      if (!regName.test(value)) {
      } else {
        this.setState({ [type]: value });
      }
    } else if (type === "cardMonth") {
      value = Number(value);
      this.setState((prevState) => ({
        [type]: value,
        toggleMonth: !prevState.toggleMonth,
      }));
    } else if (type === "cardYear") {
      value = Number(value);
      const { cardMonth } = this.state;
      if (value === CURRENT_YEAR && cardMonth <= CURRENT_MONTH) {
        this.setState((prevState) => ({
          cardMonth: 0,
          cardYear: value,
          toggleYear: !prevState.toggleYear,
          toggleMonth: !prevState.toggleMonth,
        }));
      } else {
        this.setState((prevState) => ({
          [type]: value,
          toggleYear: !prevState.toggleYear,
        }));
      }
    } else if (type === "cardCvv") {
      value = value.replace(/ /gi, "");
      if (isNaN(value)) {
        return;
      } else {
        this.setState({ [type]: value });
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  canSubmit = () => {
    const { cardNumber, cardName, cardMonth, cardYear, cardCvv } = this.state;

    return (
      cardNumber.length === 16 &&
      cardName.length > 4 &&
      cardCvv.length === 3 &&
      cardMonth !== 0 &&
      cardYear !== 0
    );
  };

  moveSlider = (event, position) => {
    position = ["year", "month"].includes(position) ? "expiration" : position;
    this.setState({ sliderLocation: position });
  };

  setFocus = (event, type) => {
    let { sliderLocation } = this.state;

    if (event.target.className.includes("year")) {
      event.stopPropagation();
    }
    this[`${type}Input`].focus();
  };

  handleClick = (event) => {
    if (!this.cvvInput.contains(event.target)) {
      this.setState({ cardFlipped: false });
    }
    if (
      this.nameCard.contains(event.target) ||
      this.nameInput.contains(event.target) ||
      this.numberCard.contains(event.target) ||
      this.numberInput.contains(event.target) ||
      this.expirationCard.contains(event.target) ||
      this.monthInput.contains(event.target) ||
      this.yearInput.contains(event.target)
    )
      return;
    this.setState({ sliderLocation: "" });
  };

  // HELPERS
  formatCardNumber = (value) => {
    let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let matches = v.match(/\d{4,16}/g);
    let match = (matches && matches[0]) || "";
    let parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  };

  getCardType = (number) => {
    let re = new RegExp("^4");
    if (number.match(re) != null) return "visa";
    re = new RegExp("^(34|37)");
    if (number.match(re) != null) return "amex";
    re = new RegExp("^5[1-5]");
    if (number.match(re) != null) return "mastercard";
    re = new RegExp("^6011");
    if (number.match(re) != null) return "discover";
    return "visa";
  };

  render() {
    // console.log("RENDERING ", this.state);
    const { cardNumber, cardName, cardMonth, cardYear, cardCvv } = this.state;
    const { submitHandler, btnText } = this.props;
    let displayNumber = [];

    for (let i = 0; i < 16; i++) {
      let displayDigit = "#";
      if (typeof cardNumber[i] !== "undefined") {
        displayDigit = i > 3 && i < 12 ? "*" : cardNumber[i];
      }
      displayNumber.push(displayDigit);
    }

    const canSubmit = !this.canSubmit();

    return (
      // <div className="card-form payment-card">
      //   <div className="card-inputs ">
      //     <form onSubmit={this.handleSubmit} className="payment-form">
      <>
        <div className="lg-input input-field">
          {/* <label htmlFor="cardNumber">Card Number</label> */}
          <label htmlFor="cardNumber">{this.props.t("title")}</label>
          <div className=" input-field-container">
            <input
              style={{ paddingLeft: "10px" }}
              className="number-input"
              id="cardNumber"
              type="text"
              onChange={(event) => this.handleChange(event, "cardNumber")}
              value={this.formatCardNumber(cardNumber)}
              ref={(node) => (this.numberInput = node)}
              maxLength="19"
            />
          </div>
        </div>
        <div className="lg-input input-field ">
          {/* <label htmlFor="cardName">Card Holder's Name</label> */}
          <label htmlFor="cardName">{this.props.t("cardName")}</label>
          <div className=" input-field-container">
            <input
              style={{ paddingLeft: "10px" }}
              className="name-input"
              id="cardName"
              type="text"
              onChange={(event) => this.handleChange(event, "cardName")}
              ref={(node) => (this.nameInput = node)}
              value={cardName}
              maxLength="24"
            />
          </div>
        </div>
        <div className="med-input ">
          <label htmlFor="cardMonth">{this.props.t("exp")}</label>
          <div className="pcf">
            <select
              className="month-input"
              id="cardMonth"
              value={cardMonth}
              onChange={(event) => this.handleChange(event, "cardMonth")}
              ref={(node) => (this.monthInput = node)}
            >
              {" "}
              <option value="0" disabled>
                Month
              </option>
              {Object.keys(MONTHS).map((monthKey) => (
                <option
                  key={monthKey}
                  value={monthKey}
                  disabled={
                    cardYear === CURRENT_YEAR &&
                    Number(monthKey) <= CURRENT_MONTH
                  }
                >
                  {MONTHS[monthKey]}
                </option>
              ))}
            </select>
            <select
              className="year-input"
              id="cardYear"
              value={cardYear}
              onChange={(event) => this.handleChange(event, "cardYear")}
              ref={(node) => (this.yearInput = node)}
            >
              {" "}
              <option value="0" disabled>
                Year
              </option>
              {YEARS.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="sm-input input-field">
          <label htmlFor="cardCvv">{this.props.t("cvvCvd")}</label>
          <div className="input-field-container">
            <input
              style={{ paddingLeft: "10px" }}
              className="cvv-input"
              id="cardCvv"
              value={cardCvv}
              onChange={(event) => this.handleChange(event, "cardCvv")}
              ref={(node) => (this.cvvInput = node)}
              maxLength="3"
            />
          </div>
        </div>
        <button
          className={`lg-input ${canSubmit ? "disabled" : ""}`}
          disabled={canSubmit}
          onClick={() => {
            submitHandler(this.state);
            // this.setState({
            //   cardNumber: "",
            //   cardName: "",
            //   cardMonth: 0,
            //   cardYear: 0,
            //   cardCvv: "",
            //   cardType: "visa",
            //   toggleMonth: true,
            //   toggleYear: true,
            // });
          }}
        >
          {btnText}
        </button>
      </>
      //     </form>
      //   </div>
      // </div>
    );
  }
}
export default withTranslation("paymentInformation")(PaymentCard);
