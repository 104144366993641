import { useState } from "react";
import { auth, db } from "../../config/firebaseSetup";
import {
  collection,
  addDoc,
  query,
  where,
  writeBatch,
  getDocs,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid } from "uuid";

const NewConversation = () => {
  const handleStartConversation = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error("No authenticated user found.");
        return;
      }
      const newConversationRef = await addDoc(collection(db, "conversations"), {
        participants: [currentUser.email, currentUser.uid],
      });

      const messagesRef = collection(
        db,
        "conversations",
        newConversationRef.id,
        "messages"
      );
      const unreadMessagesQuery = query(
        messagesRef,
        where("read", "==", false),
        where("senderId", "!=", currentUser.uid)
      );

      const batch = writeBatch(db);

      try {
        const querySnapshot = await getDocs(unreadMessagesQuery);
        querySnapshot.forEach((doc) => {
          batch.update(doc.ref, { read: true });
        });

        const newMessageRef = doc(messagesRef);
        const newMessageData = {
          senderId: uuid(),
          message: "This Is A Default Message",
          timestamp: Date.now(),
          read: false,
        };
        batch.set(newMessageRef, newMessageData);

        // Update the mostRecentMessage field in the conversations document
        const conversationRef = doc(db, "conversations", newConversationRef.id);
        batch.update(conversationRef, {
          mostRecentMessage: newMessageData,
          lastUpdated: Date.now(),
        });

        await batch.commit();

        // setNewMessage("");
      } catch (error) {
        console.error("Unable to send message:", error);
      }
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  return (
    <div>
      <Button className={"newChatButton"} onClick={handleStartConversation}>
        <FontAwesomeIcon icon={faAdd} />
      </Button>
    </div>
  );
};

export default NewConversation;
