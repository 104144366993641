import React from "react";

const TagsColors = ({ tags }) => {
  return (
    <div className="description-content">
      <h3>Tags</h3>
      <ul className="auction-tags">
        {tags.map((tag) => {
          return <li key={tag}>{tag}</li>;
        })}
      </ul>
    </div>
  );
};

export default TagsColors;
