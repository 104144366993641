import { useContext, useEffect, useRef, useState } from "react";
import { Context as AuctionContext, Context } from "../context/AuctionContext";
import AuctionItem from "../components/AuctionItem";
import Button from "../components/Button";
import Header from "../components/Header";
import { useLocation, useParams } from "react-router-dom";
import DropdownSelect from "../components/DropdownSelect";
import PriceRangeDropdown from "../components/PricerangeDropdown";
import "../styles/tagAuctionsStyles.css";
import { useTranslation } from "react-i18next";
import Loading from "../components/Loader";
import { motion } from "framer-motion";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { addDays, format, isWithinInterval, parseISO } from "date-fns";
import ExpandableList from "../components/ExpandableList";
import { Timestamp, collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebaseSetup";
import ScrollToTop from "../components/ScollToTop";
import catIcon from "../assets/icons/catIcon.png";
import filterIcon from "../assets/icons/filterIcon.png";
import CategoriesModal from "../components/CategoriesModal";
import FiltersModal from "../components/FiltersModal";

const TagAuctionsPage = () => {
  const { t } = useTranslation("dropdownSelect");
  const { category, subCategory } = useParams();

  const { getAllAuctions } = useContext(Context);
  const { state } = useContext(AuctionContext);
  const scrollIntoView = useRef();
  const location = useLocation();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [priceRange, setPriceRange] = useState(`${1}-${10000}`);
  const [categories, setCategories] = useState([]);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const datePickerRef = useRef();

  const [items, setItems] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [visibleItems, setVisibleItems] = useState([]);
  const [options, setOptions] = useState({
    filterBy: "closing",
    sortBy: null,
  });
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showFiltersModal, setShowFilterModal] = useState(false);

  // const handleSelect = (ranges) => {
  //   const { startDate, endDate } = ranges.selection;
  //   setSelectionRange({ startDate, endDate, key: "selection" });

  //   const formattedStartDate = format(startDate, "yyyy-MM-dd");
  //   const formattedEndDate = format(endDate, "yyyy-MM-dd");
  //   const currentDate = new Date();
  //   const currentFormattedDate = format(currentDate, "yyyy-MM-dd");

  //   const filteredAuctions = items.filter((auction) => {
  //     if (!auction.startDate || !auction.endDate) {
  //       return false; // Exclude auctions with undefined start or end date
  //     }

  //     const formattedAuctionStartDate = format(
  //       new Date(auction.startDate),
  //       "yyyy-MM-dd"
  //     );
  //     const formattedAuctionEndDate = format(
  //       new Date(auction.endDate),
  //       "yyyy-MM-dd"
  //     );

  //     if (formattedStartDate === formattedEndDate) {
  //       // Show auctions for the specific date if start date and end date are the same
  //       return formattedAuctionStartDate === formattedStartDate;
  //     } else if (
  //       formattedStartDate === currentFormattedDate &&
  //       formattedEndDate >= currentFormattedDate
  //     ) {
  //       // Show ongoing auctions if start date is today and end date is in the future
  //       return (
  //         formattedAuctionEndDate >= currentFormattedDate &&
  //         formattedAuctionStartDate <= formattedEndDate
  //       );
  //     } else if (
  //       formattedStartDate < currentFormattedDate &&
  //       formattedEndDate < currentFormattedDate
  //     ) {
  //       // Show ended auctions if both start date and end date are in the past
  //       return (
  //         formattedAuctionEndDate >= formattedStartDate &&
  //         formattedAuctionEndDate <= formattedEndDate
  //       );
  //     } else if (
  //       formattedStartDate < currentFormattedDate &&
  //       formattedEndDate >= currentFormattedDate
  //     ) {
  //       // Show ongoing auctions if start date is in the past and end date is today or in the future
  //       return (
  //         formattedAuctionStartDate <= currentFormattedDate &&
  //         formattedAuctionEndDate >= currentFormattedDate
  //       );
  //     } else if (
  //       formattedStartDate <= formattedEndDate &&
  //       formattedAuctionEndDate >= formattedStartDate &&
  //       formattedAuctionStartDate <= formattedEndDate
  //     ) {
  //       // Show auctions where end date is after start date from range and start date is before end date from range
  //       return true;
  //     } else if (
  //       formattedAuctionEndDate >= formattedStartDate &&
  //       formattedAuctionEndDate <= formattedEndDate &&
  //       formattedAuctionStartDate < formattedStartDate
  //     ) {
  //       // Show auctions that ended after the start date of the range
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });

  //   setVisibleItems(filteredAuctions);
  // };
  // TODO:
  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setSelectionRange({ startDate, endDate, key: "selection" });

    const selectedStartDate = startDate;
    const selectedEndDate = endDate;

    const filteredAuctions = items.filter((auction) => {
      if (!auction.startDate || !auction.endDate) {
        return false;
      }

      const auctionStartDate = parseISO(auction.startDate);
      const auctionEndDate = parseISO(auction.endDate);

      const isAuctionStartWithinRange = isWithinInterval(auctionStartDate, {
        start: selectedStartDate,
        end: selectedEndDate,
      });

      const isAuctionEndWithinRange = isWithinInterval(auctionEndDate, {
        start: selectedStartDate,
        end: selectedEndDate,
      });

      console.log(isAuctionStartWithinRange);
      console.log(isAuctionEndWithinRange);

      if (isAuctionStartWithinRange || isAuctionEndWithinRange) {
        return true;
      } else {
        return false;
      }
    });
    console.log(filteredAuctions);
    setVisibleItems(filteredAuctions);
  };

  useEffect(() => {
    getAllAuctions();
    getCategories(setCategories);
  }, []);

  useEffect(() => {
    const filteredItems =
      category && subCategory
        ? state?.allAuctions?.filter(
            (auctions) =>
              auctions?.category?.toLowerCase() === category?.toLowerCase() &&
              auctions?.subcategory?.toLowerCase() ===
                subCategory?.toLowerCase()
          )
        : state?.allAuctions;

    const initialVisibleItems = filteredItems?.slice(0, itemsPerPage);

    setItems(filteredItems);
    setVisibleItems(initialVisibleItems);
  }, [itemsPerPage, state.allAuctions, category, subCategory]);

  useEffect(() => {
    if (scrollIntoView.current) {
      scrollIntoView?.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      return;
    }
  }, [location]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    }
    document.body.addEventListener("click", handleClickOutside, true);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showCategoryModal || showFiltersModal) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  }, [showFiltersModal, showCategoryModal]);

  if (!state.allAuctions) {
    return <Loading />;
  }

  const loadMore = () => {
    const startIndex = visibleItems.length;
    const endIndex = startIndex + itemsPerPage;
    const nextVisibleItems = items
      ?.filter(filterByStatus)
      .slice(startIndex, endIndex);

    setVisibleItems((prevVisibleItems) => [
      ...prevVisibleItems,
      ...nextVisibleItems,
    ]);
  };

  const filterBy = [
    { label: "All" },
    { label: "Closed" },
    { label: "Coming Soon" },
    { label: "Closing Soon" },
    { label: "No Bids" },
  ];

  const sortBy = [{ label: "High to Low" }, { label: "Low to High" }];

  const filterByStatus = (auction) => {
    const { filterBy } = options;
    if (!filterBy || filterBy?.label?.toLowerCase() === "all") {
      return true;
    }

    const currentTimestamp = new Date().getTime();
    const startTimestamp = auction.serverStartTimeForAuction
      ? auction.serverStartTimeForAuction.toMillis()
      : null;
    const endTimestamp = auction.serverEndTimeForAuction
      ? auction.serverEndTimeForAuction.toMillis()
      : null;

    switch (filterBy?.label?.toLowerCase()) {
      case "closed":
        return endTimestamp && endTimestamp < currentTimestamp;
      case "coming soon":
        return startTimestamp && startTimestamp > currentTimestamp;
      case "closing soon":
        return (
          endTimestamp &&
          endTimestamp > currentTimestamp &&
          endTimestamp - currentTimestamp <= 172800000
        );
      case "no bids":
        return !auction.bidHistory || auction.bidHistory.length === 0;
      default:
        return true;
    }
  };

  const filterByCategories = (categoryName, subCategoryName) => {
    if (categoryName) {
      const filteredItems = state?.allAuctions?.filter(
        (auctions) =>
          auctions?.category?.toLowerCase() === categoryName?.toLowerCase()
      );
      const initialVisibleItems = filteredItems?.slice(0, itemsPerPage);
      setItems(filteredItems);
      setVisibleItems(initialVisibleItems);
      window.scrollTo(0, 0);
    }
    if (subCategoryName && categoryName) {
      const filteredItems = state?.allAuctions?.filter(
        (auctions) =>
          auctions?.category?.toLowerCase() === categoryName?.toLowerCase() &&
          auctions?.subcategory?.toLowerCase() ===
            subCategoryName?.toLowerCase()
      );

      const initialVisibleItems = filteredItems?.slice(0, itemsPerPage);

      setItems(filteredItems);
      setVisibleItems(initialVisibleItems);
      window.scrollTo(0, 0);
    }
  };

  const animationVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  const filterByPriceRange = (auction) => {
    const [minPrice, maxPrice] = priceRange.split("-").map(Number);
    const highestBid = auction.auctionStartingPrice || 0;
    return highestBid >= minPrice && highestBid <= maxPrice;
  };

  const applyPriceRangeFilter = () => {
    const filteredByPrice = items.filter(filterByPriceRange);
    setVisibleItems(filteredByPrice.slice(0, itemsPerPage));
  };

  const applyFilters = () => {
    const filteredItems = items?.filter(filterByStatus);
    // ?.filter(filterByPriceRange);
    // ?.sort(sortAuctions);
    // const filteredByPrice = filteredItems?.filter(filterByPriceRange);
    // loadMore();
    setVisibleItems(filteredItems.slice(0, itemsPerPage));
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={animationVariants}
      transition={{ duration: 0.5, delay: 0.5 }}
    >
      {showCategoryModal && (
        <CategoriesModal
          categories={categories}
          filterByCategories={filterByCategories}
          onClose={() => {
            setShowCategoryModal(false);
            setShowFilterModal(false);
          }}
        />
      )}
      {showFiltersModal && (
        <FiltersModal
          setPriceRange={setPriceRange}
          applyPriceRangeFilter={applyPriceRangeFilter}
          setOptions={setOptions}
          selectionRange={selectionRange}
          handleSelect={handleSelect}
          applyFilters={applyFilters}
          setShowDatePicker={setShowDatePicker}
          showDatePicker={showDatePicker}
          options={options}
          datePickerRef={datePickerRef}
          onClose={() => {
            setShowCategoryModal(false);
            setShowFilterModal(false);
          }}
        />
      )}
      <div className="filters-buttons">
        <button
          onClick={() => {
            setShowCategoryModal(true);
            setShowFilterModal(false);
          }}
        >
          <img src={catIcon} alt="" />
          <span>Categories</span>
        </button>
        <button
          onClick={() => {
            setShowFilterModal(true);
            setShowCategoryModal(false);
          }}
        >
          <img src={filterIcon} alt="" />
          <span>Filters</span>
        </button>
      </div>
      <section className="tagAuction">
        <div className="tagAuction-container">
          <motion.div
            className="tagAuction-dropdown"
            initial="hidden"
            animate="visible"
            variants={animationVariants}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            <DropdownSelect
              options={filterBy}
              heading={t("filterBy")}
              className={"wrapper-dropdown-width"}
              setOptions={(selectedOption) =>
                setOptions({ ...options, filterBy: selectedOption })
              }
            />
            <div className="date-picker-filter">
              <h2
                className="wrapper-dropdown"
                onClick={() => setShowDatePicker(!showDatePicker)}
              >
                By Date
              </h2>
              {showDatePicker && (
                <div className="date-picker-filter-dp" ref={datePickerRef}>
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showPreview={false}
                    className="date-picker-styles"
                    minDate={
                      new Date(new Date().getTime() + 1 * 60 * 60 * 1000)
                    }
                  />
                </div>
              )}
            </div>
            <div className="expand-container">
              <h2 style={{ marginBottom: ".6rem" }}>Categories</h2>
              {categories.map((category, index) => (
                <ExpandableList
                  key={index}
                  category={category.name}
                  subcategories={category.subcategories}
                  filterByCategories={filterByCategories}
                />
              ))}
            </div>

            <PriceRangeDropdown
              setPriceRange={setPriceRange}
              filterByPriceRange={applyPriceRangeFilter}
              heading={t("priceRange")}
            />
            <Button onClick={applyFilters}>Apply</Button>
          </motion.div>
        </div>
        <motion.div
          className="tagAuction-content"
          initial="hidden"
          animate="visible"
          variants={animationVariants}
          transition={{ duration: 1, delay: 0.4 }}
        >
          <div className="tagAuction-auctions">
            {visibleItems?.length ? (
              visibleItems.map((auction) => (
                <motion.div
                  key={auction.id}
                  initial="hidden"
                  animate="visible"
                  variants={animationVariants}
                  transition={{ duration: 2 }}
                >
                  <AuctionItem
                    key={auction.id}
                    id={auction.id}
                    auction={auction}
                  />
                </motion.div>

                // <div>{auction.data.auctionTitle}</div>
              ))
            ) : (
              <div className="no-item-div">
                <h2>No Items Right Now</h2>
              </div>
            )}
            {/* {visibleItems?.map((auction) => (
              <AuctionItem key={auction.id} auction={auction} />
            ))} */}
          </div>
          {visibleItems?.length < items?.length && (
            <div className="btn-contain">
              <Button className={"tagAuction-btn"} onClick={loadMore}>
                See More
              </Button>
            </div>
          )}
        </motion.div>
      </section>
    </motion.div>
  );
};

const getCategories = async (setCategories) => {
  try {
    const querySnapshot = await getDocs(collection(db, "categories"));
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const firstFourCategories = Object.values(data).slice(0, 4);
    setCategories(firstFourCategories);
    // console.log(data[0].subcategories[0].name);
  } catch (error) {
    setCategories([]);
    // console.error("Error fetching data: ", error);
  }
};

export default TagAuctionsPage;
