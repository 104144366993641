import React from "react";
import { auth } from "../config/firebaseSetup";

const ReservePrice = ({ auction }) => {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    return null;
  }

  if (auction && auction.bidHistory) {
    const userBid = auction.bidHistory.some(
      (userBid) => userBid.userEmail === currentUser.email
    );
    if (userBid) {
      return (
        <div
          className="reservePrice"
          style={{
            marginTop: "1rem",
            color:
              auction?.highestBid < auction.auctionReservePrice
                ? "red"
                : "green",
          }}
        >
          {auction?.highestBid < auction.auctionReservePrice
            ? "Reserve Price Not Met"
            : null}
        </div>
      );
    }
  }
  return null;
};

export default ReservePrice;
