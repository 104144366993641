import ReactDOM from "react-dom";
// import "../styles/modalStyles.css";

const VerificationModal = ({ children }) => {
  return ReactDOM.createPortal(
    <div className="modal-container mcheight">{children}</div>,
    document.querySelector("#authModal")
  );
};

export default VerificationModal;
