import { useEffect, useState } from "react";
import "../../styles/cardDisplayStyles.css";
import axios from "axios";
import Button from "../Button";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebaseSetup";
import { toast } from "react-toastify";
import fetchAndSendEmailTemplate from "../../utils/emailSend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const CardDisplay = ({
  user,
  setHaveCards,
  setShowLoader,
  getUser,
  setCardId,
  cardId,
}) => {
  const [cards, setCards] = useState(null);

  const deleteCard = async (paymentId) => {
    setShowLoader(true);
    try {
      const encryptedPaymentId = paymentId;
      const { data } = await axios.post(
        "https://stripe-server-production-3c2c.up.railway.app/delete-user-card",
        {
          paymentMethodId: encryptedPaymentId,
        }
      );
      if (data.paymentMethodDelete) {
        const userDocRef = doc(db, "users", user.uid);
        if (cards && cards.length) {
          // If there are other cards, update the paymentMethodId to the next card in the array
          const cardsWithoutDeleted = cards.filter(
            (card) => card.id !== encryptedPaymentId
          );
          if (cardsWithoutDeleted.length > 0) {
            const decryptedCardId = cardsWithoutDeleted[0].id;
            await updateDoc(userDocRef, {
              paymentMethodId: decryptedCardId,
              paymentMethodAdded: true,
            });
          } else {
            // No cards left, set paymentMethodAdded to false and paymentMethodId to null
            await updateDoc(userDocRef, {
              paymentMethodAdded: false,
              paymentMethodId: null,
            });
          }
        } else {
          // No cards left, set paymentMethodAdded to false and paymentMethodId to null
          await updateDoc(userDocRef, {
            paymentMethodAdded: false,
            paymentMethodId: null,
          });
        }
        toast.success("Payment method deleted successfully!");
        getUser(user);
        setShowLoader(false);
        if (user && user.notificationSettings.creditDeletedNotifications) {
          fetchAndSendEmailTemplate(
            "For Credit Deleted Success",
            user,
            "https://stripe-server-production-3c2c.up.railway.app/send-email"
          );
        }

        await getUser(user);
      }
    } catch (error) {
      toast.error("Error deleting Payment method");
      setShowLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const getUserCardInfo = async () => {
      setShowLoader(true);
      try {
        const { data } = await axios.post(
          // "https://stripe-server-production-3c2c.up.railway.app/get-card-info",
          "https://stripe-server-production-3c2c.up.railway.app/get-card-info",
          {
            customerId: user.customerId,
          }
        );
        setCards(data);
        setHaveCards(data.length);
        if (data && data.length === 1) {
          const userDocRef = doc(db, "users", user.uid);
          await updateDoc(userDocRef, {
            paymentMethodId: data[0].id,
            paymentMethodAdded: true,
          });
        }
        setShowLoader(false);
      } catch (error) {
        setCards(null);
        console.log(error);
        setShowLoader(false);
      }
    };
    if (user) {
      getUserCardInfo();
    }
  }, [user]);

  if (!cards) {
    return <div>NO CARDS</div>;
  }

  return cards?.map((cardInfo) => {
    return (
      <div
        className={`card-container-cc  
        ${cardId === cardInfo.id ? "selectedCard" : ""}`}
        key={cardInfo.id}
        onClick={() => setCardId(cardInfo.id)}
      >
        <div className="">
          <div className="card-flex-container">
            {/* <div className="">
              <div className="">Card</div>
              <div className="">{cardInfo.card.brand}</div>
            </div> */}
            <div className="">
              <div className="">
                <p>This account is billed to</p>
                <p className="card-info">
                  {cardInfo.card.brand} card ending in {cardInfo.card.last4}
                </p>
              </div>
            </div>
            <div className="expireNo_card">
              <div>Expires:</div>
              <div className="">
                {cardInfo.card.exp_month}/{cardInfo.card.exp_year}
              </div>
            </div>
            <Button
              className={"card-btn"}
              onClick={() => deleteCard(cardInfo.id)}
            >
              Remove Card
            </Button>
          </div>
        </div>
      </div>
    );
  });
};

export default CardDisplay;
