import { useState } from "react";
import ReactDOM from "react-dom";
import { renderInput } from "./AuctionForm";
import { FormProvider, useForm } from "react-hook-form";
import {
  editReqDescription,
  editSubjectInput,
  reportDescription,
  subjectInput,
} from "../../utils/inputValidations";
import Button from "../Button";
import DropdownSelect from "../DropdownSelect";
import { useTranslation } from "react-i18next";
import { db } from "../../config/firebaseSetup";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";
import Loading from "../Loader";

const options = [
  { label: "Title" },
  { label: "Tags" },
  { label: "Categories" },
  { label: "Time" },
];

const EditAuctionRequestForm = ({
  auction,
  auctionId,
  contactUs,
  // user={userData}
  setModal,
  collectionName,
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [loading, setLoading] = useState(false);

  const methods = useForm();

  const sendEditReq = async (reqObject) => {
    try {
      // Add the edit req as a new document in the 'edit reqs' collection
      const editReqCollectionRef = collection(
        db,
        collectionName ? collectionName : "editRequests"
      );
      await addDoc(editReqCollectionRef, reqObject);
      toast.success("Message Submitted Successfully");
      setModal(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = methods.handleSubmit((formData) => {
    setLoading(true);
    sendEditReq({
      ...formData,
      status: "pending",
      auctionId: auctionId,
      editLabel: selectedOption.label,
      images: auction.images,
    });
  });

  const { t } = useTranslation("auctionForm");

  return (
    <div style={{ marginTop: "1rem" }} className="">
      {loading && <Loading />}
      <FormProvider {...methods}>
        <form className="auction-from" onSubmit={(e) => e.preventDefault()}>
          <h2>Request for: {auction.auctionTitle} </h2>
          {renderInput(editSubjectInput, methods, t)}
          {contactUs ? null : (
            <DropdownSelect
              setOptions={setSelectedOption}
              options={options}
              heading={"Select option"}
            />
          )}
          {renderInput(editReqDescription, methods, t)}
          <Button onClick={handleSubmit}>Submit</Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default EditAuctionRequestForm;
