import "../styles/sidebarStyles.css";
import React, { useState, useEffect, useContext } from "react";
import SidebarIndex from "../context/SidebarIndex";
import { auth, db } from "../config/firebaseSetup";
import {
  billingAddressCompleted,
  cardCompleted,
  realNameCompleted,
  shippingAddressCompleted,
} from "../utils/checkuser";
import { doc, updateDoc } from "firebase/firestore";

const Sidebar = ({ tabsList, onClick, user }) => {
  // const [currentActiveTab, setCurrentActiveTab] = useState( || 0);
  const { index, setSideBarIndex } = useContext(SidebarIndex);
  const onChangeTab = (indx, tabUrl) => {
    setSideBarIndex(indx);
    if (onClick) {
      const currentUser = auth.currentUser;
      onClick(currentUser);
    }
  };
  // console.log(index);
  useEffect(() => {
    if (index) setSideBarIndex(index);
  }, [index, setSideBarIndex]);

  const updateUserProfile = async (user) => {
    if (
      user.shippingAddressSameAsBilling !== true &&
      (!shippingAddressCompleted(user) ||
        !billingAddressCompleted(user) ||
        !cardCompleted(user) ||
        !realNameCompleted(user))
    ) {
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        profileCompleted: true,
      });
    }
  };

  return (
    <div className="classic-vertical-tabs">
      <div className="tabs-labels">
        {tabsList.map((el, i) => (
          <div
            key={i}
            className={`tab-label ${index === i ? "active-tab-label" : ""}`}
            onClick={() => {
              onChangeTab(i, el.tabUrl);
            }}
          >
            {el.icon && <img src={el.icon} className="sidebar-icon" alt="" />}
            {el.label}
            {/* {i >= 2 && i <= 5 && <div className="redDotReq"></div>} */}
            {i === 2 &&
              (!shippingAddressCompleted(user) ||
                !billingAddressCompleted(user) ||
                !cardCompleted(user) ||
                !realNameCompleted(user)) && <div className="redDotReq"></div>}

            {/* {i === 3 && !billingAddressCompleted(user) && (
              <div className="redDotReq"></div>
            )} */}
            {/* {i === 4 && !cardCompleted(user) && (
              <div className="redDotReq"></div>
            )} */}
            {/* {i === 5 && !realNameCompleted(user) && (
              <div className="redDotReq"></div>
            )} */}
            {i === 3 && user?.status === "suspended" && (
              <div className="redDotReq"></div>
            )}
          </div>
        ))}
      </div>
      <div className="tabs-content">
        {tabsList.map((el, i) => (
          <div
            key={i}
            className={`tab-content ${index === i ? "active-tab-content" : ""}`}
          >
            {index === i && el.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
