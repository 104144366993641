import React, { useEffect, useState } from "react";
import InputField from "../InputField";
import { emailValidation, otpValidation } from "../../utils/inputValidations";
import { useTranslation } from "react-i18next";
import numIcon from "../../assets/icons/phone-dial-numbers.svg";
import otpIcon from "../../assets/icons/otp-icon.svg";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useWatch } from "react-hook-form";
import "../../styles/verifyEmailStyles.css";
import Loading from "../Loader";

const VerifyNumber = ({ control, onSubmit, onClose }) => {
  const [checkEmail, setCheckEmail] = useState(true);
  const [enterOtpCode, setEnterOtpCode] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { t } = useTranslation("editProfile");
  const phone = useWatch({ name: "phoneNumber" });

  const verifyEmailHandler = () => {
    setCheckEmail(false);
    setEnterOtpCode(true);
    setShowLoader(true);
    onSubmit();
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);
  };

  return (
    <div className="verify-con">
      {showLoader && <Loading />}
      <div className="verify-heading">
        <h4>Verify Your Number</h4>
        <button onClick={onClose}>
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
      {checkEmail && (
        <>
          <div className="verify-icon">
            <img src={numIcon} alt="verifyIcon" />
          </div>
          <InputField
            type={"number"}
            placeholder={""}
            name={"phoneNumber"}
            id={"phoneNumber"}
            validationRules={{ required: true }}
            label={t("Enter Phone Number")}
          />
          <p>Please enter your number you want to use and verify</p>
          <Button
            className={"verify-btn"}
            onClick={() => {
              setCheckEmail(false);
              setEnterOtpCode(true);
            }}
          >
            Send Code
          </Button>
        </>
      )}
      {enterOtpCode && (
        <>
          <div className="verify-icon">
            <img src={otpIcon} alt="" />
          </div>
          <p>
            Please enter the 6 digits code sent to {phone}{" "}
            <button
              className="change-email"
              onClick={() => {
                setCheckEmail(true);
                setEnterOtpCode(false);
              }}
            >
              Change Email
            </button>
          </p>

          <div>
            <InputField
              type={"number"}
              placeholder={""}
              name={"otpCode"}
              id={"otp"}
              validationRules={otpValidation}
              label={t("Enter Code")}
            />
          </div>
          <Button className={"verify-btn"} onClick={verifyEmailHandler}>
            Verify
          </Button>
        </>
      )}
    </div>
  );
};

export default VerifyNumber;
