import { Link } from "react-router-dom";
import { useState, useContext, useEffect, useRef } from "react";
import searchIcon from "../assets/icons/search.svg";
import bellIcon from "../assets/icons/bell.svg";
import closeIcon from "../assets/icons/close.svg";
import "../styles/headerStyles.css";
import "../styles/navStyles.css";
import usaIcon from "../assets/icons/united-flag.svg";
import engIcon from "../assets/icons/british-flag.svg";
import italyFlag from "../assets/icons/italy-flag.svg";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import { useNavigate, useLocation } from "react-router-dom";
import Dropdown from "./Dropdown";
import { useUser } from "../context/UserContext";
import SidebarIndex from "../context/SidebarIndex";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  onSnapshot,
} from "@firebase/firestore";
import { auth, db } from "../config/firebaseSetup";
import Search from "./search/Search";
import SearchItem from "./search/SearchItem";
import logo from "../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faClose } from "@fortawesome/free-solid-svg-icons";
import UserNotificationModal from "./UserNotificationModal";
import { AnimatePresence } from "framer-motion";
import {
  billingAddressCompleted,
  cardCompleted,
  realNameCompleted,
  shippingAddressCompleted,
} from "../utils/checkuser";

export const Header = () => {
  const { setSideBarIndex } = useContext(SidebarIndex);
  const { user, login, showLoginModalHandler, logout } = useUser();
  const { t } = useTranslation("header");
  const navigate = useNavigate();
  const location = useLocation();
  const divEl = useRef();
  const navRef = useRef();
  const searchRef = useRef(null);

  // const [ searchTerm, setSearchTerm ] = useState("");
  const [isMenuActive, setMenuActive] = useState(false);
  const [isSearchActive, setSearchActive] = useState(false);

  const [isNotificationsActive, setNotificationsActive] = useState(false);
  const [hasUnRead, setUnRead] = useState(false);
  const [categories, setCategories] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const [userData, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const snapshotUnsubscribe = onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            setUser(doc.data());
            getNotifications(doc.data(), setNotifications, setUnRead);
          } else {
            setUser(null);
          }
        });

        return () => snapshotUnsubscribe(); // Unsubscribe from user document snapshot
      } else {
        setUser(null); // Clear user data if no user is signed in
      }
    });

    return () => unsubscribe(); // Unsubscribe from auth state changes
  }, []);

  // const getUser = async (uid) => {
  //   try {
  //     const userDocRef = doc(db, "users", uid);
  //     const docSnap = await getDoc(userDocRef);
  //     if (docSnap.exists()) {
  //       setUser(docSnap.data());
  //     } else {
  //       setUser(null);
  //     }
  //   } catch (error) {
  //     setUser(null);
  //   }
  // };

  useEffect(() => {
    // const currentUser = auth.currentUser;
    if (user) {
      // getUser(user.uid);
      // getNotifications(user, setNotifications, setUnRead);
    }
  }, [user]);

  const [selection, setSelection] = useState(null);

  const options = [
    { label: engIcon, value: "en" },
    { label: italyFlag, value: "it" },
  ];

  useEffect(() => {
    if (selection) {
      i18n.changeLanguage(selection.value);
      document.documentElement.lang = i18n.language;
      localStorage.setItem("languageSelection", JSON.stringify(selection));

      if (location.pathname.startsWith("/product/")) {
        const newPath = location.pathname.replace(
          /(\/product\/)[^\/]+/,
          `$1${selection.value}`
        );
        navigate(newPath, { replace: true });
      }
    }
  }, [selection]);

  const handleSelection = (option) => {
    if (option !== selection) {
      setSelection(option);
    }
  };

  const checkLocalStorageUser = () => {
    const storedUser = JSON.parse(localStorage.getItem("loginUser"));
    if (storedUser) {
      // console.log("user in localStorage is :", storedUser);
      const parsedUser = storedUser;
      login(parsedUser);
      // navigate("/");
    }
  };
  // console.log(userData);
  useEffect(() => {
    checkLocalStorageUser();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        const menuCheckbox = document.getElementById("menu-btn");
        if (menuCheckbox && menuCheckbox.checked) {
          menuCheckbox.checked = false;
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLinkClick = () => {
    const menuCheckbox = document.getElementById("menu-btn");
    if (menuCheckbox && menuCheckbox.checked) {
      menuCheckbox.checked = false;
    }
  };

  useEffect(() => {
    const handler = (e) => {
      if (!divEl.current) {
        return;
      }
      if (!divEl.current.contains(e.target)) {
        setNotificationsActive(false);
      }
    };

    document.addEventListener("click", handler, true);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  useEffect(() => {
    const storedSelection = localStorage.getItem("languageSelection");
    if (storedSelection) {
      const parsedSelection = JSON.parse(storedSelection);
      setSelection(parsedSelection);
      // i18n.changeLanguage(parsedSelection.value);
    } else {
      const detectedLanguage = navigator.language.split("-")[0];
      if (detectedLanguage === "it") {
        setSelection({ label: italyFlag, value: "it" });
      } else {
        setSelection({ label: engIcon, value: "en" });
      }
      // i18n.changeLanguage(defaultSelection.value);
    }

    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchActive(false);
      }
    }
    document.body.addEventListener("click", handleClickOutside, true);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getCategories(setCategories);
  }, []);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  const toggleSearch = () => {
    setSearchActive(!isSearchActive);
  };

  return (
    <nav ref={navRef}>
      <div className="nav-wrapper">
        <div className="logo">
          <Link to="/" onClick={handleLinkClick}>
            <img src={logo} alt="logo" width={"50px"} />
          </Link>
        </div>
        <div className="btn-nav-icon">
          <li
            style={{ cursor: "pointer", marginLeft: "10px" }}
            onClick={toggleSearch}
            className="icons-nav"
          >
            <img src={searchIcon} alt="" width={"20px"} />
          </li>

          <li
            style={{ cursor: "pointer" }}
            onClick={() => setNotificationsActive(!isNotificationsActive)}
            className="bell-btn icons-nav"
          >
            <img src={bellIcon} alt="" width={20} />
            {hasUnRead && <div className="unreadDot"></div>}
          </li>

          <label htmlFor="menu-btn" className="btn bars menu-btn">
            <FontAwesomeIcon icon={faBars} color="black" />
          </label>
        </div>
        <input type="radio" name="slider" id="menu-btn" />
        <input type="radio" name="slider" id="close-btn" />
        <ul className="nav-links">
          <label htmlFor="close-btn" className="btn close-btn">
            <FontAwesomeIcon icon={faClose} color="black" />
          </label>
          <li onClick={handleLinkClick}>
            <Link to="/">{t("home")}</Link>
          </li>

          {user ? (
            <li>
              <Link to="#" className="desktop-item">
                {t("profile")}
              </Link>
              <input type="checkbox" id="showDrop" />
              <label htmlFor="showDrop" className="mobile-item">
                Profile
              </label>
              <ul className="drop-menu">
                <li
                  onClick={() => {
                    if (window.location.pathname !== "/profile") {
                      navigate("/profile");
                    }
                    if (isMenuActive) {
                      setMenuActive(false);
                    }
                    setSideBarIndex(0);
                    handleLinkClick();
                  }}
                >
                  <Link to="#">{t("submenu1")}</Link>
                </li>
                <li
                  onClick={() => {
                    if (window.location.pathname !== "/profile") {
                      navigate("/profile");
                    }
                    if (isMenuActive) {
                      setMenuActive(false);
                    }
                    setSideBarIndex(1);
                    handleLinkClick();
                  }}
                >
                  <Link to="#">{t("submenu2")}</Link>
                </li>
                <li
                  onClick={() => {
                    if (window.location.pathname !== "/profile") {
                      navigate("/profile");
                    }
                    if (isMenuActive) {
                      setMenuActive(false);
                    }
                    setSideBarIndex(2);
                    handleLinkClick();
                  }}
                >
                  <Link to="#">
                    {userData &&
                      (!shippingAddressCompleted(userData) ||
                        !billingAddressCompleted(userData) ||
                        !cardCompleted(userData) ||
                        !realNameCompleted(userData)) && (
                        <div className="completeProfileIndicator"></div>
                      )}
                    Personal Info
                    {/* {t("submenu9")} */}
                  </Link>
                </li>
                {/* <li
                  onClick={() => {
                    if (window.location.pathname !== "/profile") {
                      navigate("/profile");
                    }
                    if (isMenuActive) {
                      setMenuActive(false);
                    }
                    setSideBarIndex(2);
                    handleLinkClick();
                  }}
                >
                  <Link to="#">
                    {userData && !shippingAddressCompleted(userData) && (
                      <div className="completeProfileIndicator"></div>
                    )}
                    {t("submenu3")}
                  </Link>
                </li> */}
                {/* <li
                  onClick={() => {
                    if (window.location.pathname !== "/profile") {
                      navigate("/profile");
                    }
                    if (isMenuActive) {
                      setMenuActive(false);
                    }
                    setSideBarIndex(3);
                    handleLinkClick();
                  }}
                >
                  <Link to="#">
                    {userData && !billingAddressCompleted(userData) && (
                      <div className="completeProfileIndicator"></div>
                    )}
                    {t("submenu4")}
                  </Link>
                </li> */}
                {/* <li
                  onClick={() => {
                    if (window.location.pathname !== "/profile") {
                      navigate("/profile");
                    }
                    if (isMenuActive) {
                      setMenuActive(false);
                    }
                    setSideBarIndex(4);
                    handleLinkClick();
                  }}
                >
                  <Link to="#">
                    {userData && !cardCompleted(userData) && (
                      <div className="completeProfileIndicator"></div>
                    )}
                    {t("submenu5")}
                  </Link>
                </li> */}
                {/* <li
                  onClick={() => {
                    if (window.location.pathname !== "/profile") {
                      navigate("/profile");
                    }
                    if (isMenuActive) {
                      setMenuActive(false);
                    }
                    setSideBarIndex(5);
                    handleLinkClick();
                  }}
                >
                  <Link to="#">
                    {userData && !realNameCompleted(userData) && (
                      <div className="completeProfileIndicator"></div>
                    )}
                    {t("submenu6")}
                  </Link>
                </li> */}
                {userData?.isASeller ? (
                  <li
                    onClick={() => {
                      if (window.location.pathname !== "/profile") {
                        navigate("/profile");
                      }
                      if (isMenuActive) {
                        setMenuActive(false);
                      }
                      setSideBarIndex(3);
                      handleLinkClick();
                    }}
                  >
                    <Link to="#">
                      {userData && userData?.status === "suspended" && (
                        <div className="redDotReq"></div>
                      )}
                      {t("submenu7")}
                    </Link>
                  </li>
                ) : (
                  <li
                    onClick={() => {
                      if (window.location.pathname !== "/profile") {
                        navigate("/profile");
                      }
                      if (isMenuActive) {
                        setMenuActive(false);
                      }
                      setSideBarIndex(3);
                      handleLinkClick();
                    }}
                  >
                    <Link to="#">
                      {userData && userData?.status === "suspended" && (
                        <div className="redDotReq"></div>
                      )}
                      Become a seller
                      {/* {t("submenu8")} */}
                    </Link>
                  </li>
                )}
                <li
                  onClick={() => {
                    if (window.location.pathname !== "/profile") {
                      navigate("/profile");
                    }
                    if (isMenuActive) {
                      setMenuActive(false);
                    }
                    setSideBarIndex(4);
                    handleLinkClick();
                  }}
                >
                  <Link to="#">{t("submenu9")}</Link>
                </li>
                <li
                  onClick={() => {
                    if (window.location.pathname !== "/profile") {
                      navigate("/profile");
                    }
                    if (isMenuActive) {
                      setMenuActive(false);
                    }
                    setSideBarIndex(5);
                    handleLinkClick();
                  }}
                >
                  <Link to="#">
                    Notifications
                    {/* {t("submenu9")} */}
                  </Link>
                </li>

                <li
                  onClick={() => {
                    if (window.location.pathname !== "/profile") {
                      navigate("/profile");
                    }
                    if (isMenuActive) {
                      setMenuActive(false);
                    }
                    setSideBarIndex(6);
                    handleLinkClick();
                  }}
                >
                  <Link to="#">{t("submenu10")}</Link>
                </li>
              </ul>
            </li>
          ) : (
            <li className="menu-item">
              <Link to={"#"} onClick={showLoginModalHandler}>
                {t("login")}
              </Link>
            </li>
          )}
          <li>
            <Link to="/auctions" className="desktop-item">
              Auctions
            </Link>
            <input type="checkbox" id="showMega" />
            <Link
              onClick={handleLinkClick}
              to="/auctions"
              htmlFor="showMega"
              className="mobile-item"
            >
              Auctions
            </Link>
            <div className="mega-box">
              <div className="content">
                {renderMegaLinks(categories, handleLinkClick)}
              </div>
            </div>
          </li>
          <li onClick={handleLinkClick}>
            <Link to="/contactUs"> {t("contactUs")}</Link>
          </li>
          {user && (
            <li
              className="menu-item"
              onClick={() => {
                localStorage.removeItem("loginUser");
                logout();
                auth.signOut();
                handleLinkClick();
                // Check if the current route is '/profile'
                if (location.pathname === "/profile") {
                  navigate("/");
                }
              }}
            >
              <Link to={"#"}>{t("logout")}</Link>
            </li>
          )}
          <li
            style={{ cursor: "pointer", marginLeft: "10px" }}
            onClick={() => {
              toggleSearch();
              handleLinkClick();
            }}
            className="icons-nav mob"
          >
            <img src={searchIcon} alt="" width={"20px"} />
          </li>
          <li
            style={{ cursor: "pointer" }}
            onClick={() => {
              setNotificationsActive(!isNotificationsActive);
              handleLinkClick();
            }}
            className="bell-btn icons-nav mob"
          >
            <img src={bellIcon} alt="" width={20} />
            {hasUnRead && <div className="unreadDot"></div>}
          </li>

          <li
            style={{ cursor: "pointer" }}
            // onClick={() => setNotificationsActive(!isNotificationsActive)}
            className="bell-btn mob"
          >
            <Dropdown
              options={options}
              onChange={handleSelection}
              value={selection}
              iconOnly={true}
            />
          </li>

          <li className="bell-btn dsk">
            <Dropdown
              options={options}
              onChange={handleSelection}
              value={selection}
              iconOnly={true}
            />
          </li>
        </ul>
      </div>

      <AnimatePresence>
        {isSearchActive && (
          <div ref={searchRef} className="search-block is-active">
            <Search
              closeIcon={closeIcon}
              toggleSearch={toggleSearch}
              setSearchActive={setSearchActive}
            />
          </div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isNotificationsActive && (
          <div className="noti-user" ref={divEl}>
            <UserNotificationModal
              hasUnRead={hasUnRead}
              setUnRead={setUnRead}
              user={user}
              notifications={notifications}
            />
          </div>
        )}
      </AnimatePresence>
    </nav>
  );
};

const renderMegaLinks = (categories, handleLinkClick) => {
  return categories.map((cat) => (
    <div className="row" key={cat.id}>
      <header>{cat.name}</header>
      <ul className="mega-links">
        {cat.subcategories.map((subCat) => (
          <li key={subCat.id} onClick={handleLinkClick}>
            <Link to={`/category/${cat.name}/subCategory/${subCat.name}`}>
              {subCat.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  ));
};

const getCategories = async (setCategories) => {
  try {
    const querySnapshot = await getDocs(collection(db, "categories"));
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const firstFourCategories = Object.values(data).slice(0, 4);
    setCategories(firstFourCategories);
    // console.log(data[0].subcategories[0].name);
  } catch (error) {
    setCategories([]);
    // console.error("Error fetching data: ", error);
  }
};

const getNotifications = (user, setNotifications, setUnRead) => {
  if (user) {
    const userDocRef = doc(db, "users", user.uid);

    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      try {
        if (docSnapshot.exists() && docSnapshot.data().notifications) {
          const userNotifications = docSnapshot.data().notifications;
          // Update the state with the new notifications
          setNotifications(userNotifications);
          const hasUnRead = userNotifications.some(
            (notification) => !notification.read
          );
          setUnRead(hasUnRead);
        }
        return unsubscribe;
      } catch (error) {
        setNotifications([]);
      }
    });
  }
};

export default Header;
