import { useEffect, useState } from "react";
import "../styles/countDownStyles.css";

function Countdown({ serverTime }) {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const updateCountdown = () => {
      // Get current time in the user's local time zone
      const currentDateLocal = new Date();

      // Convert Unix timestamp to JavaScript Date
      const targetDate = new Date(serverTime);

      const timeDifference = targetDate - currentDateLocal;

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeRemaining({ days, hours, minutes, seconds });
      }
    };

    const intervalId = setInterval(updateCountdown, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [serverTime]);

  // Get the user's time zone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Format the target date based on the user's time zone
  const formattedTargetDate = new Date(serverTime).toLocaleString("en-US", {
    timeZone: userTimeZone,
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return (
    <>
      <p className="timezone-cd">
        {formattedTargetDate} ({userTimeZone})
      </p>
      <div className="count-down">
        <p>
          <span>Days</span>
          <span className="firstSpan">{timeRemaining.days}</span>
        </p>
        <p>
          <span>Hours</span>{" "}
          <span className="firstSpan">{timeRemaining.hours}</span>
        </p>
        <p>
          <span>Minutes</span>
          <span className="firstSpan">{timeRemaining.minutes}</span>
        </p>
        <p>
          <span>Seconds</span>
          <span className="firstSpan">{timeRemaining.seconds}</span>
        </p>
      </div>
    </>
  );
}

export default Countdown;
