import ReactDOM from "react-dom";
import "../styles/loaderStyles.css";

const Loading = ({ onClose }) => {
  return ReactDOM.createPortal(
    <div className="overlay-container">
      <div className="example">
        <span className="smooth spinner" />
      </div>
    </div>,
    // <div className="overlay-container">
    //   <div id="outer">
    //     <div id="middle">
    //       <div id="inner"></div>
    //     </div>
    //   </div>
    // </div>,
    document.querySelector("#loader")
  );
};

export default Loading;
