import { useEffect, useState } from "react";
import "../../styles/privacyStyles.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebaseSetup";
import Loader from "../../components/Loader";

const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(false);
  const [ppContent, setPpContent] = useState(null);

  const fetchContent = async () => {
    setLoading(true);
    try {
      const ppDocRef = doc(db, "websiteContent", "ppContent");
      const ppDocSnap = await getDoc(ppDocRef);

      if (ppDocSnap.exists()) {
        setPpContent(ppDocSnap.data().content);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <div>
        {!ppContent && !loading ? (
          <h2
            style={{
              height: "100vh",
            }}
          >
            Nothing here. Please try again later
          </h2>
        ) : (
          <section dangerouslySetInnerHTML={{ __html: ppContent }} />
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
