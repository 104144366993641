import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import ContactUsPage from "../../pages/ContactUsPage";
import transition from "../../utils/transition";

const Contact = () => {
  return (
    <div>
      {/* <Header /> */}
      <ContactUsPage />
      <Newsletter />
      <Footer color={"black"} />
    </div>
  );
};

export default transition(Contact);
