import { useState } from "react";
import ToggleButton from "../ToggleButton";
import Button from "../Button";
import Loading from "../Loader";
import { auth, db } from "../../config/firebaseSetup";
import { doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const NotificationsSettings = ({ user, getUser }) => {
  const [emailOn, setEmailOn] = useState(user.emailNotifications || false);
  const [phoneOn, setPhoneOn] = useState(user.phoneNotifications || false);
  const [whatsAppOn, setWhatsAppOn] = useState(
    user.whatsAppNotifications || false
  );
  const [loginNotifications, setLoginNotifications] = useState(
    user?.notificationSettings?.loginNotifications || false
  );

  // const [auctionAcceptNotifications, setAuctionAcceptNotifications] = useState(
  //   user?.notificationSettings?.auctionAcceptNotifications || false
  // );

  const [
    auctionRequestSubmitNotifications,
    setAuctionRequestSubmitNotifications,
  ] = useState(
    user?.notificationSettings?.auctionRequestSubmitNotifications || false
  );

  const [tagRequestSubmitNotifications, setTagRequestSubmitNotifications] =
    useState(
      user?.notificationSettings?.tagRequestSubmitNotifications || false
    );

  // const [
  //   auctionRequestRejectedNotifications,
  //   setAuctionRequestRejectedNotifications,
  // ] = useState(
  //   user?.notificationSettings?.auctionRequestRejectedNotifications || false
  // );

  const [auctionWonNotifications, setAuctionWonNotifications] = useState(
    user?.notificationSettings?.auctionWonNotifications || false
  );

  const [creditAddedNotifications, setCreditAddedNotifications] = useState(
    user?.notificationSettings?.creditAddedNotifications || false
  );

  const [creditDeletedNotifications, setCreditDeletedNotifications] = useState(
    user?.notificationSettings?.creditDeletedNotifications || false
  );

  const [paymentSuccessNotifications, setPaymentSuccessNotifications] =
    useState(user?.notificationSettings?.paymentSuccessNotifications || false);

  const [showLoader, setShowLoader] = useState(false);
  // const currentUser = auth.currentUser;

  const submitHandler = async () => {
    setShowLoader(true);

    const notificationSettings = {
      loginNotifications: loginNotifications,
      // auctionAcceptNotifications: auctionAcceptNotifications,
      auctionRequestSubmitNotifications: auctionRequestSubmitNotifications,
      tagRequestSubmitNotifications: tagRequestSubmitNotifications,
      // auctionRequestRejectedNotifications: auctionRequestRejectedNotifications,
      auctionWonNotifications: auctionWonNotifications,
      creditAddedNotifications: creditAddedNotifications,
      creditDeletedNotifications: creditDeletedNotifications,
      paymentSuccessNotifications: paymentSuccessNotifications,
    };
    try {
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        whatsAppNotifications: whatsAppOn,
        emailNotifications: emailOn,
        phoneNotifications: phoneOn,
        notificationSettings: notificationSettings,
      });
      toast.success("Changes Saved");
      await getUser(user);

      setShowLoader(false);
    } catch (error) {
      toast.error("Error saving your changes");
      setShowLoader(false);
    }
  };

  return (
    <div style={{ padding: "0 1rem" }}>
      {showLoader && <Loading />}
      <h2 className="bid-now-info">
        Welcome to the notification settings section! Here, you're in the
        driver's seat. Take a moment to select your preferences and customize
        your experience just the way you like it. Your choices matter to us, so
        feel free to tailor your notifications to suit your needs.
      </h2>
      <h2 className="noti-head">Notifications Medium</h2>
      <div className="notifications-btns-container">
        <div className="my-settings">
          <h2>Via Email</h2>
          <ToggleButton on={emailOn} setOnState={setEmailOn} />
        </div>
        <div className="my-settings">
          <h2>Via Phone Number</h2>
          <ToggleButton on={phoneOn} setOnState={setPhoneOn} />
        </div>
        <div className="my-settings">
          <h2>Via What'sApp</h2>
          <ToggleButton on={whatsAppOn} setOnState={setWhatsAppOn} />
        </div>
      </div>

      <h2 className="noti-head">Individual </h2>
      <div>
        <div className="my-settings">
          <h2>Login Notification</h2>
          <ToggleButton
            on={loginNotifications}
            setOnState={setLoginNotifications}
          />
        </div>

        {/* <div className="my-settings">
          <h2>Auction Accept Notification</h2>
          <ToggleButton
            on={auctionAcceptNotifications}
            setOnState={setAuctionAcceptNotifications}
          />
        </div> */}

        <div className="my-settings">
          <h2>Auction Request Submit </h2>
          <ToggleButton
            on={auctionRequestSubmitNotifications}
            setOnState={setAuctionRequestSubmitNotifications}
          />
        </div>

        {/* <div className="my-settings">
          <h2>Auction Request Rejected Notification</h2>
          <ToggleButton
            on={auctionRequestRejectedNotifications}
            setOnState={setAuctionRequestRejectedNotifications}
          />
        </div> */}

        <div className="my-settings">
          <h2>Auction Won </h2>
          <ToggleButton
            on={auctionWonNotifications}
            setOnState={setAuctionWonNotifications}
          />
        </div>

        <div className="my-settings">
          <h2>Tag Request Submitted </h2>
          <ToggleButton
            on={tagRequestSubmitNotifications}
            setOnState={setTagRequestSubmitNotifications}
          />
        </div>

        <div className="my-settings">
          <h2>Credit Card Added </h2>
          <ToggleButton
            on={creditAddedNotifications}
            setOnState={setCreditAddedNotifications}
          />
        </div>

        <div className="my-settings">
          <h2>Credit Card Deleted </h2>
          <ToggleButton
            on={creditDeletedNotifications}
            setOnState={setCreditDeletedNotifications}
          />
        </div>

        <div className="my-settings">
          <h2>Payment Success </h2>
          <ToggleButton
            on={paymentSuccessNotifications}
            setOnState={setPaymentSuccessNotifications}
          />
        </div>
      </div>
      <Button onClick={submitHandler} className={"noti-btn"}>
        Save Changes
      </Button>
    </div>
  );
};

export default NotificationsSettings;
