import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "../styles/bidsModalStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEuro } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import euro from "../assets/icons/euro.svg";

const BidsModal = ({ bids, onClose }) => {
  const [formattedTimestamp, setFormattedTimestamp] = useState(null);
  // console.log(bids.timestamp);
  useEffect(() => {
    if (bids && bids.timestamp) {
      const date = new Date(bids.timestamp);

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      const formattedTime = `${hours}:${minutes}:${seconds}`;

      const formattedTimestamp = `${formattedDate} ${formattedTime}`;
      setFormattedTimestamp(formattedTimestamp);
    }
  }, [bids]);

  return ReactDOM.createPortal(
    <motion.div
      className="bidModal-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="heading-biider">
        <h2>Bids History</h2>
        <button className="close-btn" onClick={onClose}>
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
      {!bids ? (
        <h2>No Bids Yet</h2>
      ) : (
        <>
          <div className="heading">
            <div className="heading-item">Bidders</div>
            <div className="heading-item">Amount</div>
            <div className="heading-item">Date</div>
          </div>
          <div>
            {bids.map((bid, index) => {
              return (
                <div className="bidders" key={index}>
                  <div className="nationality bgi">
                    <span
                      style={{ fontSize: "30px" }}
                      className={`fi fi-${bid.nationality?.id?.toLowerCase()} fis`}
                    />
                    <h2>{bid.username}</h2>
                  </div>
                  <div className="bidder-ammount bgi">
                    {/* <FontAwesomeIcon icon={faEuro} /> */}
                    <img src={euro} alt="" width={"20px"} height={"20px"} />
                    <h2>{bid.bidAmount}</h2>
                  </div>
                  <div className="bgi">{formatTimestamp(bid.timestamp)}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </motion.div>,
    document.querySelector("#auctionDupFormModal")
  );
};

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return (
    <div className="date-bider">
      <div>{`${day}/${month}/${year}`}</div>
      <div>{` ${hours}:${minutes}:${seconds}`}</div>
    </div>
  );
};

export default BidsModal;
