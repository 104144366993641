import AuctionItem from "../../AuctionItem";
import Loading from "../../Loader";
import "../sliderStyles/sliderItem.css";
import { useNavigate } from "react-router-dom";

const SliderItem = ({ auction, width }) => {
  const navigate = useNavigate();
  if (!auction) {
    return null;
  }

  return (
    <div
      // onClick={() => navigate(`/auction-item/${auction.id}`)}
      className="slider-item slider-item-image"
      // style={{ width: `${width}%` }}
    >
      {/* <img
        className="slider-image"
        src={auction?.images[0].fileimage}
        alt={auction?.title}
      /> */}
      <AuctionItem auction={auction} id={auction?.id} />
    </div>
  );
};

export default SliderItem;
