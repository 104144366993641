import HomePage from "../../pages/HomePage";
import Footer from "../../components/Footer";
import Newsletter from "../../components/Newsletter";
import transition from "../../utils/transition";

const Home = () => {
  return (
    <div style={{ height: "100%" }}>
      <HomePage />
      <Newsletter />
      <Footer color={"black"} />
    </div>
  );
};

export default transition(Home);
