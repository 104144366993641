import { useState, useEffect, useRef } from "react";
import PriceRangeSlider from "./PriceRange";
import "../styles/dropdownSelectStyles.css";

const PriceRangeDropdown = ({
  heading,
  setPriceRange,
  filterByPriceRange,
  onClose,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(`$${1}-$${20000}`);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleDocumentClick(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    }

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  function toggleDropdown() {
    setIsActive(!isActive);
  }

  function handleOptionClick() {
    // setSelectedOption(option);
    setIsActive(false);
  }

  const onApply = (min, max) => {
    console.log(min, max);
    handleOptionClick();
    setSelectedOption(`$${min}-$${max}`);
    setPriceRange(`${min}-${max}`);
    filterByPriceRange();
    if (onClose) {
      onClose();
    }
  };

  const handleSliderClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="dropdown-select-container">
      <h2>{heading}</h2>
      <div
        className={`wrapper-dropdown ${isActive ? "active" : ""}`}
        ref={dropdownRef}
        onClick={toggleDropdown}
      >
        <span>{selectedOption}</span>
        <ul className="price-range-dropdown">
          {/* {options.map((option, index) => ( */}
          <li onClick={handleSliderClick}>
            <PriceRangeSlider onApply={onApply} />
            {/* {option} */}
          </li>
          {/* ))} */}
        </ul>
      </div>
    </div>
  );
};

export default PriceRangeDropdown;
