import { useState } from "react";
import "../styles/expandableListStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const ExpandableList = ({
  category,
  subcategories,
  filterByCategories,
  onClose,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    filterByCategories(category);
  };

  const handleSubcategorySelect = (subcategory) => {
    // console.log(subcategory);
    filterByCategories(category, subcategory);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="expandable-list-container">
      <div
        className="expandable-list-container-heading"
        onClick={toggleExpand}
        style={{ cursor: "pointer" }}
      >
        <strong>
          <FontAwesomeIcon icon={isExpanded ? faMinus : faPlus} /> {category}
        </strong>
      </div>
      {isExpanded && (
        <ul>
          {subcategories.map((subcategory) => (
            <li key={subcategory.id} className="sub_cats-btns">
              <button onClick={() => handleSubcategorySelect(subcategory.name)}>
                {/* <input
                  type="checkbox"
                  checked={selectedSubcategories.includes(subcategory.name)}
                  onChange={() => handleSubcategorySelect(subcategory.name)}
                /> */}
                {subcategory.name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ExpandableList;
