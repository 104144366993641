import { Link, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../components/InputField";
import userIcon from "../assets/icons/user.svg";
import emailIcon from "../assets/icons/email.svg";
import Button from "../components/Button";
import DropdownSelect from "../components/DropdownSelect";
import "../styles/contactUsStyles.css";

import { emailValidation, usernameValidation } from "../utils/inputValidations";

const options = [
  { label: "General" },
  { label: "Issues" },
  { label: "Anything else" },
  { label: "Anything else" },
];

const ContactUsPage = () => {
  const navigate = useNavigate();
  const methods = useForm();

  const submitHandler = methods.handleSubmit((formData) => {
    console.log(formData);
    methods.reset();
  });

  return (
    <div className=" contact-us">
      <div>
        <h1>Get in Touch with Us</h1>
        <p>
          Feel free to reach out to us with any questions or inquiries. We're
          here to help!
        </p>
      </div>

      <FormProvider {...methods}>
        <form action="" onSubmit={(e) => e.preventDefault()}>
          <InputField
            type={"text"}
            placeholder={"User Name"}
            name={"username"}
            id={"username"}
            label={"Username"}
            icon={userIcon}
            validationRules={usernameValidation}
          />
          <InputField
            label={"Email"}
            type={"email"}
            placeholder={"Email"}
            name={"email"}
            id={"email"}
            icon={emailIcon}
            validationRules={emailValidation}
          />
          <DropdownSelect options={options} heading={"Select a topic"} />
          <InputField
            label={"Your Message"}
            type={"text"}
            placeholder={"Type your message here..."}
            name={"userMessage"}
            id={"usermessage"}
            multiline={true}
            // icon={emailIcon}
            validationRules={{
              required: {
                value: true,
                message: "message is required",
              },
            }}
          />
          <Button className="login-page-btn" onClick={submitHandler}>
            Send Message
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default ContactUsPage;
