import { useEffect } from "react";
import { auth } from "./config/firebaseSetup";
import { useUser } from "./context/UserContext";
import { useLocation, useNavigate } from "react-router-dom";

const AuthProvider = ({ children }) => {
  // const { logout } = useUser();
  // const navigate = useNavigate();
  // const location = useLocation();

  // useEffect(() => {
  //   const handleAuthStateChange = (user) => {
  //     if (user) {
  //       // User is signed in
  //       const lastLoginTimestamp = new Date().getTime();
  //       localStorage.setItem(
  //         "lastLoginTimestamp",
  //         lastLoginTimestamp.toString()
  //       );
  //       const timeoutDuration = 24 * 60 * 60 * 1000;
  //       // const timeoutId = setTimeout(logoutUser, 3600000); // 1 hour in milliseconds
  //       const timeoutId = setTimeout(logoutUser, timeoutDuration); // 1 hour in milliseconds

  //       return () => clearTimeout(timeoutId); // Clean up the timeout on unmount
  //     } else {
  //       // User is signed out
  //       localStorage.removeItem("lastLoginTimestamp");
  //     }
  //   };

  //   const logoutUser = () => {
  //     auth.signOut();
  //     if (location.pathname === "/profile") {
  //       navigate("/");
  //     }
  //     localStorage.removeItem("loginUser");
  //     localStorage.removeItem("lastLoginTimestamp");
  //     logout();
  //   };

  //   const unsubscribe = auth.onAuthStateChanged(handleAuthStateChange);

  //   return unsubscribe;
  // }, [location, logout, navigate]);

  return <>{children}</>;
};

export default AuthProvider;
