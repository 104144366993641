import axios from "axios";
import createDateContext from "./createDataContext";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "../config/firebaseSetup";

const auctionReducer = (state, action) => {
  switch (action.type) {
    case 'get_auctions':
      return { ...state, auctions: action.payload.auctions };
    case 'getAllAuctions':
      return { ...state, allAuctions: action.payload };
    default:
      return state;
  }
};

// const getAuctions = (dispatch) => {
//   return async () => {
//     const { data } = await axios.get('/auctions.json');
//     dispatch({ type: 'get_auctions', payload: data });
//   };
// };

const getAllAuctions = (dispatch) => {
  return async () => {
    try {
      const auctionsColRef = collection(db, "Auction");
      const excludedStatuses = [ "pending", "suspended", "trashed", "draft", "rejected", ];

      const q = query(
        auctionsColRef,
        where("status", "not-in", excludedStatuses),
        orderBy("status"),
        orderBy("created", "desc")
      );
      const querySnapshot = await getDocs(q);

      const auctionsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch({ type: 'getAllAuctions', payload: auctionsData });
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  };
};
// const getAllAuctions = (dispatch) => {
//   return async (pageNumber = 1, pageSize = 12) => {
//     try {
//       const auctionsColRef = collection(db, "Auction");
//       const excludedStatuses = [ "pending", "suspended", "trashed", "draft" ];

//       const q = query(
//         auctionsColRef,
//         where("status", "not-in", excludedStatuses),
//         // orderBy("created", "desc"),
//         limit(pageSize),
//         // offset((pageNumber - 1) * pageSize)
//       );
//       const querySnapshot = await getDocs(q);

//       const auctionsData = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));

//       dispatch({ type: 'getAllAuctions', payload: auctionsData });
//     } catch (error) {
//       console.error("Error getting documents: ", error);
//       throw error;
//     }
//   };
// };

export const { Context, Provider } = createDateContext(
  auctionReducer,
  { getAllAuctions },
  [],
);