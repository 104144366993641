import InputField from "../InputField";
import {
  addressValidation,
  cityValidation,
  provinceValidation,
  postalCodeValidation,
  addressNameValidation,
  usernameValidation,
} from "../../utils/inputValidations";
import "../../styles/addressCardStyles.css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import PhoneNumber from "react-phone-number-input";
import "react-phone-number-input/style.css";

const AddressCard = ({
  isSameAddress,
  setIsSameAddress,
  phoneNumber,
  setPhoneNumber,
  style = {},
}) => {
  const { t } = useTranslation("shippingAddress");

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.type === "number") {
        e.preventDefault();
      }
    };

    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div style={style} className="address-card">
      <h3>{t("title")}</h3>
      {isSameAddress ? null : (
        <div>
          <div className="address-card-three-fields">
            <InputField
              type={"text"}
              placeholder={""}
              name={"addressShipping"}
              id={"address"}
              validationRules={addressValidation}
              label={"Address"}
            />
            <InputField
              type={"text"}
              placeholder={"Name"}
              name={"shippingAddressName"}
              id={"shippingAddressName"}
              validationRules={usernameValidation}
              label={"Name"}
            />

            <InputField
              type={"text"}
              placeholder={"Surname"}
              name={"shippingAddressSurname"}
              id={"shippingAddressSurname"}
              validationRules={usernameValidation}
              label={"Surname"}
            />
          </div>

          <div className="address-card-three-fields">
            <InputField
              type={"text"}
              placeholder={""}
              name={"apartmentShipping"}
              id={"apartment"}
              validationRules={{}}
              label={"Apartment"}
            />
            <InputField
              type={"text"}
              placeholder={""}
              name={"cityShipping"}
              id={"city"}
              validationRules={cityValidation}
              label={"City"}
            />

            <div>
              <label htmlFor="">Phone Number</label>
              <div className="phone-input">
                <PhoneNumber
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  international
                  defaultCountry="RU"
                />
              </div>
            </div>
          </div>
          <div className="address-card-three-fields">
            <InputField
              type={"text"}
              placeholder={""}
              name={"countryShipping"}
              id={"country"}
              validationRules={{}}
              label={"Country"}
            />
            <InputField
              type={"text"}
              placeholder={""}
              name={"state-provinceShipping"}
              id={"apartment"}
              validationRules={provinceValidation}
              label={"State Province"}
            />
            <div className="spanmore">
              <InputField
                type={"text"}
                placeholder={""}
                name={"postal-codeShipping"}
                id={"postalCode"}
                validationRules={postalCodeValidation}
                label={"PostalCode"}
              />
            </div>
          </div>
        </div>
      )}
      <div className="sameAsAddress">
        <input
          type="checkbox"
          checked={isSameAddress}
          onChange={(e) => setIsSameAddress(e.target.checked)}
          id="shippingAndBillingAddressSame"
        />
        <label htmlFor="shippingAndBillingAddressSame">
          Same as Billing Address
        </label>
      </div>
    </div>
  );
};

export default AddressCard;
