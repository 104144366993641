import "../styles/toggleButtonStyle.css";

function ToggleButton({ on, setOnState }) {
  const toggle = () => setOnState((o) => !o);
  return (
    <button className={`toggleButton  ${on ? "on" : "off"}`} onClick={toggle}>
      <span className="pin" />
    </button>
  );
}

export default ToggleButton;
