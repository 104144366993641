import { useContext, useEffect, useState } from "react";
import { Context as AuctionContext } from "../context/AuctionContext";
import Slider from "../components/slider/Slider";
import Hero from "../components/Hero";
import "../styles/homepageStyles.css";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebaseSetup";

const AnimatedSection = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 100 },
      }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.section>
  );
};

const HomePage = () => {
  const { state, getAllAuctions } = useContext(AuctionContext);
  const [categories, setCagetories] = useState(null);
  const { t } = useTranslation("home");

  useEffect(() => {
    getAllAuctions();
    getCategories(setCagetories);
  }, []);

  return (
    <div>
      <AnimatedSection>
        <Hero />
      </AnimatedSection>
      {categories && categories.length ? (
        <>
          {categories.map((cat) => (
            <AnimatedSection key={cat.id}>
              <Slider auctions={state.allAuctions} filterFor={cat.name} />
            </AnimatedSection>
          ))}
        </>
      ) : null}
      {/* <AnimatedSection>
        <Slider auctions={state.allAuctions} filterFor={"Smartphones"} />
      </AnimatedSection>
      <AnimatedSection>
        <Slider auctions={state.allAuctions} filterFor={"Bracelets"} />
      </AnimatedSection>
      <AnimatedSection>
        <Slider auctions={state.allAuctions} filterFor={"Home and Garden"} />
      </AnimatedSection> */}
    </div>
  );
};

export default HomePage;

const getCategories = async (setCategories) => {
  try {
    const querySnapshot = await getDocs(collection(db, "categories"));
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const firstFourCategories = Object.values(data).slice(0, 4);
    setCategories(firstFourCategories);
    // console.log(data[0].subcategories[0].name);
  } catch (error) {
    setCategories([]);
    // console.error("Error fetching data: ", error);
  }
};
