import { useFormContext } from "react-hook-form";
import euroIcon from "../../assets/icons/euro.svg";
// import { currencyValidation } from "../../utils/inputValidations";
import InputField from "../InputField";

const BidNowCard = ({ minValue, quickBids }) => {
  // console.log(minValue);
  const { getValues } = useFormContext();
  const bidValueAmount = getValues("bid-amount");
  const currencyValidation = {
    required: {
      value: true,
      message: "currency is required",
    },
    min: {
      value: minValue,
      message: `Minimum value is ${minValue}`,
    },
    pattern: {
      value: /^\d+(\.\d+)?$/,
      message: `Please enter a valid positive number greater than or equal to ${minValue}`,
    },
    // validate: {
    //   inQuickBids: (value) => {
    //     if (!quickBids.includes(parseFloat(value))) {
    //       return `Your bid must be one of the following values: ${quickBids.join(
    //         ", "
    //       )}`;
    //     }
    //     return true;
    //   },
    // },
  };

  const optionalValidation = {
    validate: {
      greaterThanOrEqualToMinValue: (value) => {
        const numericValue = parseInt(value);
        if (
          value !== "" &&
          numericValue !== 0 &&
          numericValue <= Number(bidValueAmount) + 10
        ) {
          return `Optional bid must be 0 or greater than ${
            Number(bidValueAmount) + 10
          }`;
        }
        return true;
      },
    },
  };

  return (
    <div className="bid-now-card">
      <h3>You are about to make an offer of</h3>
      <div className="bid-now-input-container">
        <InputField
          type={"number"}
          placeholder={0}
          name={"bid-amount"}
          id={"bid-amount"}
          icon={euroIcon}
          validationRules={currencyValidation}
          label={"Your bid"}
        />
        <InputField
          type={"number"}
          placeholder={0}
          name={"bid-amount-optional"}
          id={"bid-amount-optional"}
          icon={euroIcon}
          validationRules={optionalValidation}
          label={"Your max bid(optional)"}
        />
      </div>
      <div className="bid-now-info">
        <p>
          Remember that all offers are binding. You are agreeing to pay for this
          item should you be the highest bidder.
        </p>
      </div>
    </div>
  );
};

export default BidNowCard;
