import React from "react";

const IconBtn = ({ onClick, children, iconName, activeIcon }) => {
  return (
    <button
      className={`auctions-btn-card ${
        activeIcon === iconName ? "bgColoractive" : ""
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default IconBtn;
