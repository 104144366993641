import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/filtersModals.css";
import Overlay from "./Overlay";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ExpandableList from "./ExpandableList";
import { useEffect } from "react";

const CategoriesModal = ({ onClose, categories, filterByCategories }) => {
  useEffect(() => {
    handleWindowResize(window);
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const handleWindowResize = (e) => {
    if (window.innerWidth > 1023) {
      onClose();
    }
  };

  return (
    <div className="filters-modals">
      <Overlay onClose={onClose} />
      <div className="filters-modals-content">
        <div className="icon-modal">
          <h2>Categories</h2>
          <FontAwesomeIcon
            icon={faClose}
            onClick={onClose}
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className="expand-container ">
          <div className="cat-overflow">
            {categories.map((category, index) => (
              <ExpandableList
                onClose={onClose}
                hide={true}
                key={index}
                category={category.name}
                subcategories={category.subcategories}
                filterByCategories={filterByCategories}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesModal;
