import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Button from "./Button";
import { renderInput } from "./userAuctionsDashboard/AuctionForm";
import { reportDescription, subjectInput } from "../utils/inputValidations";
import DropdownSelect from "./DropdownSelect";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../config/firebaseSetup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Loading from "./Loader";

const options = [
  { label: "Fraud" },
  { label: "Inappropriate Content" },
  { label: "Anything else" },
  { label: "Anything else" },
];

const ReportAuctionForm = ({ auction, user, setModal }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [loading, setLoading] = useState(false);

  const methods = useForm();
  // console.log(user);
  // const handleSubmit = methods.handleSubmit((formData) => {

  //   console.log(formData);
  //   const reportObject = {
  //     reporter: {
  //       userId: user.uid,
  //       userEmail: user.email,
  //       username: user.username,
  //     },
  //     reportedItem: {
  //       itemId: auction.id,
  //       auctionTitle: auction.auctionTitle,
  //     },
  //     reportReason: formData.reportDescription,
  //     reportSubject:formData.reportSubject,
  //     timestamp: serverTimestamp(),
  //     status: "open",
  //   };
  // });

  // const handleSubmit = methods.handleSubmit(async (formData) => {
  //   // Check if the user has already reported this item
  //   const reportsCollectionRef = collection(db, "reports");

  //   const reportsQuery = query(
  //     reportsCollectionRef,
  //     where("reporter.userId", "==", user.uid),
  //     where("reportedItem.itemId", "==", auction.id)
  //   );

  //   const querySnapshot = await getDocs(reportsQuery);

  //   if (!querySnapshot.empty) {
  //     // User has already reported this item
  //     toast.error("You have already reported this item");
  //     setModal(false);
  //     return;
  //   }

  //   // User hasn't reported this item, proceed with the report
  //   const reportObject = {
  //     itemId: auction.id,
  //     auctionTitle: auction.auctionTitle,
  //     images: auction.images,
  //     reports: [
  //       {
  //         userId: user.uid,
  //         userEmail: user.email,
  //         username: user.username,
  //         reportReason: formData.reportDescription,
  //         reportSubject: formData.reportSubject,
  //         timestamp: serverTimestamp(),
  //       },
  //     ],

  //     status: "open",
  //   };

  //   // Store the report in the 'reports' collection
  //   await addReportToFirestore(reportObject);
  // });

  // const addReportToFirestore = async (reportObject) => {
  //   // Add the report to the 'reports' collection
  //   const reportsCollectionRef = collection(db, "reports");
  //   await addDoc(reportsCollectionRef, reportObject);
  //   toast.success("Report submission success");
  //   setModal(false);
  // };

  // console.log(user);
  const handleSubmit = methods.handleSubmit(async (formData) => {
    // Check if the auction item has been reported
    // console.log(user);
    // console.log(formData);
    setLoading(true);
    try {
      const reportsCollectionRef = collection(db, "reports");
      const reportsQuery = query(
        reportsCollectionRef,
        where("itemId", "==", auction.id)
      );

      const querySnapshot = await getDocs(reportsQuery);

      if (!querySnapshot.empty) {
        // Auction item already has reports, check if the user has already reported this item
        const hasUserReported = querySnapshot.docs.some((doc) => {
          const report = doc.data();
          return report.reports.some((r) => r.userId === user.uid);
        });

        if (hasUserReported) {
          // User has already reported this item
          toast.error("You have already reported this item");
          setModal(false);
          setLoading(false);
          return;
        }
        // User hasn't reported this item, find the document to update the report array
        const docId = querySnapshot.docs[0].id;
        const existingReport = querySnapshot.docs[0].data();

        await updateReportInFirestore(docId, existingReport, formData);
      } else {
        // Auction item hasn't been reported yet, create a new report document
        const reportObject = {
          itemId: auction.id,
          auctionTitle: auction.auctionTitle,
          images: auction.images,
          reports: [
            {
              userId: user.uid,
              userEmail: user.email,
              username: user.username,
              reportReason: formData.reportDescription,
              reportSubject: formData.reportSubject,
              reportLabel: selectedOption.label,
            },
          ],

          timestamp: serverTimestamp(),
          auctionStatus: auction.status,
        };

        await addReportToFirestore(reportObject);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occured during reporting");
      setLoading(false);
    }
  });

  const updateReportInFirestore = async (docId, existingReport, formData) => {
    try {
      const reportsCollectionRef = collection(db, "reports");
      const docRef = doc(reportsCollectionRef, docId);
      // Add the new report to the existing reports array
      existingReport.reports.push({
        userId: user.uid,
        userEmail: user.email,
        username: user.username,
        reportReason: formData.reportDescription,
        reportSubject: formData.reportSubject,
        reportLabel: selectedOption.label,
      });

      // const reportsCollectionRef = collection(db, "reports");
      await updateDoc(docRef, {
        reports: existingReport.reports,
        // timestamp: serverTimestamp(),
        auctionStatus: auction.status,
      });
      // const docRef = doc(reportsCollectionRef, docId);

      // Update the existing document with the modified reports array
      // await setDoc(docRef, existingReport);
      toast.success("Report submission success");
      setModal(false);
      setLoading(false);
    } catch (error) {
      toast.error("An Error has occured");
    }
  };

  const addReportToFirestore = async (reportObject) => {
    // Add the report as a new document in the 'reports' collection
    const reportsCollectionRef = collection(db, "reports");
    await addDoc(reportsCollectionRef, reportObject);
    toast.success("Report submission success");
    setModal(false);
    setLoading(false);
  };

  const { t } = useTranslation("auctionForm");

  return (
    <div style={{ paddingTop: "1rem" }}>
      {loading && <Loading />}
      <FormProvider {...methods}>
        <form className="auction-from" onSubmit={(e) => e.preventDefault()}>
          <h2>Report</h2>
          {renderInput(subjectInput, methods, t)}
          <DropdownSelect
            setOptions={setSelectedOption}
            options={options}
            heading={"Select a topic"}
          />
          {renderInput(reportDescription, methods, t)}
          <Button onClick={handleSubmit}>Submit</Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default ReportAuctionForm;
