import InputField from "../InputField";
import {
  emailValidation,
  usernameValidation,
} from "../../utils/inputValidations";
import "../../styles/addressCardStyles.css";
import icon from "../../assets/icons/user-icon.svg";
import { useTranslation } from "react-i18next";
import DropdownSelect from "../DropdownSelect";
import { countries } from "../../utils/countries";
import { useEffect, useRef, useState } from "react";
import Overlay from "../Overlay";
import VerificationModal from "../VerificationModal";
import VerifyEmail from "../userAuctionsDashboard/VerifyEmail";
import PhoneNumber from "react-phone-number-input";
import "react-phone-number-input/style.css";

const EditProfile = ({
  style = {},
  control,
  userNationality,
  setNationality,
  setShowNumberModal,
  setShowEmailModal,
  user,
  phoneNumber,
  setPhoneNumber,
}) => {
  const { t } = useTranslation("editProfile");
  const [active, setActive] = useState(false);

  const divEl = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (!divEl.current) {
        return;
      }
      if (!divEl.current.contains(e.target)) {
        setActive(false);
      }
    };

    document.addEventListener("click", handler, true);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  const handleOptions = (option) => {
    setNationality(option);
  };

  return (
    <div style={style} className="address-card ">
      <h3>{t("title")}</h3>
      <div className="">
        {user.nationality && (
          <div className="nationality-edit">
            {/* <img src={} width={"130px"} alt="" /> */}
            <span
              style={{ fontSize: "120px" }}
              className={`fi fi-${userNationality?.id?.toLowerCase()} fis`}
            />
            <div>
              <h2>
                {t("nationality")}: {userNationality?.label}
              </h2>
            </div>
          </div>
        )}
        <div>
          <div className="address-card-two-fields">
            <InputField
              type={"text"}
              placeholder={""}
              name={"firstName"}
              id={"firstName"}
              validationRules={usernameValidation}
              label={t("firstName")}
            />
            <InputField
              type={"text"}
              placeholder={""}
              name={"lastName"}
              id={"lastName"}
              validationRules={usernameValidation}
              label={t("lastName")}
            />
          </div>
          <InputField
            type={"text"}
            placeholder={""}
            name={"nickname"}
            id={"nickname"}
            validationRules={usernameValidation}
            label={"Nickname"}
            disabled={user.nickname ? true : false}
          />

          <div>
            <InputField
              type={"email"}
              placeholder={""}
              name={"email"}
              id={"email"}
              validationRules={emailValidation}
              label={"Email"}
              disabled={true}
            />
            {/* <button onClick={() => setShowEmailModal(true)}>
              Verify Email
            </button> */}
          </div>

          <div>
            <label htmlFor="">Phone Number</label>
            <div className="phone-input">
              <PhoneNumber
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                international
                defaultCountry="RU"
              />
            </div>

            {/* <InputField
              type={"number"}
              placeholder={""}
              name={"phoneNumber"}
              id={"phoneNumber"}
              validationRules={{}}
              label={"PhoneNumber"}
            /> */}
          </div>
          {/* <button onClick={() => setShowNumberModal(true)}>
            Verify Number
          </button> */}
          <div className="scrollable-div">
            {!user.nationality ? (
              <div
                ref={divEl}
                className={`${active && "pos-div"}`}
                // onClick={() => setActive(true)}
              >
                <DropdownSelect
                  options={countries}
                  heading={"Update Nationality"}
                  // className={"wrapper-dropdown-width"}
                  className={"bs"}
                  setOptions={handleOptions}
                  toggleActive={() => {
                    setActive(!active);
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
