import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { db } from "../../config/firebaseSetup";
import SearchItem from "./SearchItem";
import Button from "../Button";
import { motion } from "framer-motion";
import Fuse from "fuse.js";

const Search = ({ toggleSearch, closeIcon, setSearchActive }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [noItemsFound, setNoItemsFound] = useState(false);

  const methods = useForm();

  const submitHandler = methods.handleSubmit(({ search }) => {
    performSearch(search);
  });

  const performSearch = async (searchTerm) => {
    const excludedStatuses = [
      "pending",
      "suspended",
      "trashed",
      "draft",
      "rejected",
    ];

    const auctionsRef = collection(db, "Auction");

    try {
      setShowLoading(true);
      const querySnapshot = await getDocs(
        query(auctionsRef, where("status", "not-in", excludedStatuses))
      );

      const allItems = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const fuse = new Fuse(allItems, {
        keys: [
          "auctionTitle",
          "descriptionEnglish",
          "descriptionItalian",
          "auctionTitleItalian",
          "tags",
        ],
        includeMatches: true,
        findAllMatches: true,
        threshold: 0.45,
      });

      const results = fuse
        .search(searchTerm.toLowerCase())
        .map((result) => result.item);

      if (results.length === 0) {
        // If no exact matches are found, suggest close matches
        const closeMatches = fuse.search(searchTerm.toLowerCase(), {
          limit: 15,
        });
        setSearchResults(closeMatches.map((match) => match.item));
        setNoItemsFound(true);
      } else {
        setSearchResults(results);
        setNoItemsFound(false);
      }

      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
      console.error("Error searching:", error);
    }
  };

  // const performSearch = async (searchTerm) => {
  //   const auctionsRef = collection(db, "Auction");

  //   try {
  //     setShowLoading(true);
  //     const querySnapshot = await getDocs(
  //       query(auctionsRef, where("status", "==", "approved"))
  //     );

  //     const results = querySnapshot.docs
  //       .map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }))
  //       .filter((item) => {
  //         const titleMatch = item.auctionTitle
  //           .toLowerCase()
  //           .includes(searchTerm.toLowerCase());

  //         const descriptionMatch = item.descriptionEnglish
  //           .toLowerCase()
  //           .includes(searchTerm.toLowerCase());

  //         const descriptionMatchIt = item.descriptionItalian
  //           .toLowerCase()
  //           .includes(searchTerm.toLowerCase());

  //         const titleIt = item.auctionTitleItalian
  //           .toLowerCase()
  //           .includes(searchTerm.toLowerCase());

  //         const tagsMatch =
  //           item.tags &&
  //           item.tags
  //             .map((tag) => tag.toLowerCase())
  //             .includes(searchTerm.toLowerCase());
  //         return (
  //           titleMatch ||
  //           descriptionMatch ||
  //           tagsMatch ||
  //           titleIt ||
  //           descriptionMatchIt
  //         );
  //       });

  //     setSearchResults(results);
  //     setShowLoading(false);
  //     setNoItemsFound(results.length === 0);
  //   } catch (error) {
  //     setShowLoading(false);
  //     console.error("Error searching:", error);
  //   }
  // };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <FormProvider {...methods}>
        <form className="search-form" onSubmit={(e) => e.preventDefault()}>
          <span className="search-cancel" onClick={toggleSearch}>
            <img src={closeIcon} alt="" width={"20px"} />
          </span>
          <input
            type={"text"}
            id={"search"}
            name={"search"}
            className="search-input"
            placeholder="Search here..."
            {...methods.register("search", { required: true, minLength: 3 })}
          />
          <Button onClick={submitHandler}>Search</Button>
        </form>
        {showLoading && (
          <div className="spinner_container" aria-label="Loading...">
            <i className="spinner_item"></i>
            <i className="spinner_item"></i>
          </div>
        )}
        {noItemsFound && <p className="sip">No items found.</p>}
        <SearchItem
          searchedItems={searchResults}
          setSearchActive={setSearchActive}
        />
      </FormProvider>
    </motion.div>
  );
};

export default Search;
