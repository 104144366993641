import React, { useState, useEffect } from "react";
import "../styles/priceRangeSliderStyles.css";

const PriceRangeSlider = ({ onApply }) => {
  const [minPrice, setMinPrice] = useState(1);
  const [maxPrice, setMaxPrice] = useState(20000);
  const priceGap = 1000;
  const rangeInput = document.querySelectorAll(".range-input input");

  useEffect(() => {
    const updateRange = () => {
      const minVal = parseInt(rangeInput[0].value);
      const maxVal = parseInt(rangeInput[1].value);

      if (maxVal - minVal < priceGap) {
        // If the range is less than the minimum gap
        const adjustedMin = Math.max(0, maxVal - priceGap);
        const adjustedMax = Math.min(10000, minVal + priceGap);

        setMinPrice(adjustedMin);
        setMaxPrice(adjustedMax);

        rangeInput[0].value = adjustedMin;
        rangeInput[1].value = adjustedMax;
      } else {
        setMinPrice(minVal);
        setMaxPrice(maxVal);
      }
    };

    rangeInput[0]?.addEventListener("input", updateRange);
    rangeInput[1]?.addEventListener("input", updateRange);

    return () => {
      rangeInput[0]?.removeEventListener("input", updateRange);
      rangeInput[1]?.removeEventListener("input", updateRange);
    };
  }, [rangeInput]);

  return (
    <div className="d-flex">
      <div className="wrapper">
        <div className="headers">
          <h2>Price Range</h2>
        </div>
        <div className="price-input">
          <div className="field">
            <span>Min</span>
            <input
              type="number"
              className="input-min"
              value={minPrice}
              onChange={(e) => setMinPrice(parseInt(e.target.value))}
            />
          </div>
          <div className="separator">-</div>
          <div className="field">
            <span>Max</span>
            <input
              type="number"
              className="input-max"
              value={maxPrice}
              onChange={(e) => setMaxPrice(parseInt(e.target.value))}
            />
          </div>
        </div>
        <div className="slider-price">
          <div
            className="progress"
            style={{
              left: `${(minPrice / 20000) * 100}%`,
              right: `${100 - (maxPrice / 20000) * 100}%`,
            }}
          ></div>
        </div>
        <div className="range-input">
          <input
            type="range"
            className="range-min"
            min="0"
            max="20000"
            value={minPrice}
            step="100"
            onChange={(e) => setMinPrice(parseInt(e.target.value))}
          />
          <input
            type="range"
            className="range-max"
            min="0"
            max="20000"
            value={maxPrice}
            step="100"
            onChange={(e) => setMaxPrice(parseInt(e.target.value))}
          />
        </div>
        <button
          className="apply-btn"
          onClick={() => onApply(minPrice, maxPrice)}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default PriceRangeSlider;
