import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import "../../styles/datePickerStyles.css";
import { useTranslation } from "react-i18next";
import TimePicker from "./TimePicker";
import { format } from "date-fns";

const formatDate = (date) => format(new Date(date), "MMMM d, yyyy");
const formatTimeToAMPM = (timeString) =>
  format(new Date(`1970-01-01T${timeString}`), "h:mm a");

const currentDate = new Date();
const currentHour = String(currentDate.getHours()).padStart(2, "0");
const currentMinute = String(currentDate.getMinutes()).padStart(2, "0");
const defaultTime = `${currentHour}:${currentMinute}`;

// Component
const DateRangePicker = ({ defaultStartDate, defaultEndDate }) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation(["auctionForm", "errors"]);

  const startDate = watch("startDate", defaultStartDate || "");
  const endDate = watch("endDate", defaultEndDate || "");
  const startTime = watch("startTime", defaultTime || "");
  const endTime = watch("endTime", "");

  useEffect(() => {
    const selectEndDate = document.querySelector("[data-js-end-date]");
    const selectStartDate = document.querySelector("[data-js-start-date]");

    const endDateClear = (bool) => {
      selectEndDate.disabled = bool;
    };

    const setMinStartDate = () => {
      const today = new Date().toISOString().split("T")[0];
      selectStartDate.min = today;
      if (selectStartDate.value < today) {
        setValue("startDate", today);
      }

      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowString = tomorrow.toISOString().split("T")[0];

      selectEndDate.min = tomorrowString;
    };

    selectStartDate.addEventListener("input", () => {
      endDateClear(false);
      setMinStartDate();
    });

    selectStartDate.addEventListener("clear", () => {
      endDateClear(true);
      setMinStartDate();
    });

    setMinStartDate();
  }, [setValue]);

  useEffect(() => {
    if (endDate && endTime) {
      const [hours, minutes] = endTime.split(":");
      const selectedEndDate = new Date(endDate);
      selectedEndDate.setHours(hours, minutes); // Set selected time to the selected end date
      console.log(selectedEndDate.toISOString().split("T")[0]);
      // Update only the time part of endDate keeping the date the same
      setValue("endDate", selectedEndDate.toISOString().split("T")[0]); // Update endDate in the form
    }
  }, [setValue]);

  const validateDate = (value) => {
    const selectedDate = new Date(value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return selectedDate < today
      ? "Selected date must be today or in the future"
      : undefined;
  };

  // const validateEndDate = (value, allValues) => {
  //   const endDate = new Date(value);
  //   const startDateObj = new Date(startDate);
  //   startDateObj.setHours(0, 0, 0, 0);

  //   // Check if start date and end date are the same
  //   if (endDate.toDateString() === startDateObj.toDateString()) {
  //     const [startHours, startMinutes] = startTime.split(":");
  //     const [endHours, endMinutes] = allValues.endTime.split(":");

  //     const startDateTime = new Date(startDate);
  //     startDateTime.setHours(
  //       parseInt(startHours),
  //       parseInt(startMinutes),
  //       0,
  //       0
  //     );

  //     const endDateTime = new Date(endDate);
  //     endDateTime.setHours(parseInt(endHours), parseInt(endMinutes), 0, 0);

  //     // Check if end time is less than start time when both dates are the same
  //     if (endDateTime < startDateTime) {
  //       return "End time must be greater than or equal to start time when the dates are the same";
  //     }
  //   }

  //   // Existing validation for end date being after start date
  //   return endDate < startDateObj
  //     ? "End date must be after the start date"
  //     : undefined;
  // };
  const validateEndDate = (value, allValues) => {
    const endDate = new Date(value);
    const startDateObj = new Date(startDate);
    startDateObj.setHours(0, 0, 0, 0);

    // Check if start date and end date are the same
    if (endDate.toDateString() === startDateObj.toDateString()) {
      const [startHours, startMinutes] = startTime.split(":");
      const [endHours, endMinutes] = allValues.endTime.split(":");

      const startDateTime = new Date(startDate);
      startDateTime.setHours(
        parseInt(startHours),
        parseInt(startMinutes),
        0,
        0
      );

      const endDateTime = new Date(endDate);
      endDateTime.setHours(parseInt(endHours), parseInt(endMinutes), 0, 0);

      const timeDiff = endDateTime.getTime() - startDateTime.getTime();
      const hoursDiff = timeDiff / (1000 * 60 * 60);

      // Check if the difference between end time and start time is at least 12 hours
      if (hoursDiff < 12) {
        return "End time must be at least 12 hours after the start time when the dates are the same";
      }
    }

    // Existing validation for end date being after start date
    return endDate < startDateObj
      ? "End date must be after the start date"
      : undefined;
  };

  const validateTime = (value) => {
    return undefined;
  };

  return (
    <div className="dpw">
      <div className="datepicker-wrapper">
        <div className="l">
          <label htmlFor="start">{t("startDate")}</label>
          <input
            style={{ marginBottom: "7px" }}
            className="datepicker"
            type="date"
            id="start"
            data-js-start-date
            value={startDate}
            onChange={(e) => setValue("startDate", e.target.value)}
            min={new Date().toISOString().split("T")[0]}
            {...register("startDate", {
              required: "Start date is required",
              validate: validateDate,
            })}
          />
          {errors.startDate && (
            <span className="inputErrors">
              {t(errors.startDate.message, { ns: "errors" })}
            </span>
          )}
        </div>
        <div className="l">
          <label htmlFor="startTime">{t("startTime")}</label>
          <TimePicker
            setValue={setValue}
            validate={validateDate}
            register={register}
            value={startTime}
            id={"startTime"}
          />
          {/* <input
            type="time"
            id="startTime"
            className="datepicker"
            value={startTime}
            onChange={(e) => setValue("startTime", e.target.value)}
            {...register("startTime", {
              required: "Start time is required",
              validate: validateTime,
            })}
          /> */}
          {errors.startTime && (
            <span className="inputErrors">
              {t(errors.startTime.message, { ns: "errors" })}
            </span>
          )}
        </div>
        <div className="l">
          <label htmlFor="end">{t("endDate")}</label>
          <input
            style={{ marginBottom: "7px" }}
            className="datepicker"
            type="date"
            id="end"
            data-js-end-date
            value={endDate}
            onChange={(e) => setValue("endDate", e.target.value)}
            min={startDate}
            {...register("endDate", {
              required: "End date is required",
              validate: validateEndDate,
            })}
          />
          {errors.endDate && (
            <span className="inputErrors">
              {t(errors.endDate.message, { ns: "errors" })}
            </span>
          )}
        </div>

        <div className="l">
          <label htmlFor="endTime">{t("endTime")}</label>
          {/* <input
            type="time"
            id="endTime"
            className="datepicker"
            value={endTime}
            onChange={(e) => setValue("endTime", e.target.value)}
            {...register("endTime", {
              required: "End time is required",
              validate: validateTime,
            })}
          /> */}
          <TimePicker
            setValue={setValue}
            validate={validateDate}
            register={register}
            value={endTime}
            id={"endTime"}
          />
          {errors.endTime && (
            <span className="inputErrors">
              {t(errors.endTime.message, { ns: "errors" })}
            </span>
          )}
        </div>
      </div>
      <div className="flex-date-time">
        <div>
          <div className="selected-dates">
            {startDate && (
              <span>
                {t("startDate")}({formatDate(startDate)})
              </span>
            )}
          </div>
          <div className="selected-dates">
            {endDate && (
              <span>
                {t("endDate")}({formatDate(endDate)})
              </span>
            )}
          </div>
        </div>
        <div>
          <div className="selected-dates">
            {startTime && (
              <span>
                {t("startTime")}({formatTimeToAMPM(startTime)})
              </span>
            )}
          </div>
          <div className="selected-dates">
            {endTime && (
              <span>
                {t("endTime")}({formatTimeToAMPM(endTime)})
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
