import { useState } from "react";
import "../styles/newlatterStyles.css";
import Button from "./Button";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const { t } = useTranslation("newsletter");
  const [email, setEmail] = useState("");
  const [tosAccepted, setTosAccepted] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log({ email, tosAccepted });
  };

  return (
    <section className="promotion-section">
      <h2>Stay in the Loop with Promotions & Updates!</h2>
      <p>
        Experience seamless empowerment through thoroughly researched growth
        strategies and interoperable internal or "organic" sources, fostering
        credible innovation at a granular internal level.
      </p>
      <form onSubmit={(e) => e.preventDefault()} className="newsletter-form">
        <input type="email" placeholder="Enter your mail" />
        <button type="submit">Subscribe</button>
      </form>
    </section>
  );
};

export default Newsletter;
