import React, { useState, useEffect } from "react";
import SliderControl from "./sliderComponents/SliderControl";
import SliderItem from "./sliderComponents/SliderItem";
import InfiniteCarousel from "react-leaf-carousel";

import "./sliderStyles/sliderStyles.css";
import { useParams } from "react-router-dom";

const Slider = (props) => {
  const [sliderHasMoved, setSliderHasMoved] = useState(false);
  const [sliderMoving, setSliderMoving] = useState(false);
  const [movePercentage, setMovePercentage] = useState(0);
  const [filteredAuction, setFilteredAuction] = useState([]);
  const [sliderMoveDirection, setSliderMoveDirection] = useState(null);
  const [lowestVisibleIndex, setLowestVisibleIndex] = useState(0);
  const [itemsInRow, setItemsInRow] = useState(0);
  const { urlId } = useParams();

  const { auctions, filterFor, hideHeading } = props;
  const totalItems = filteredAuction?.length;

  useEffect(() => {
    handleWindowResize(window);
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    if (urlId) {
      setFilteredAuction(
        auctions?.filter(
          (auction) =>
            auction?.category?.toLowerCase() === filterFor?.toLowerCase() &&
            auction.id !== urlId
        )
      );
    } else {
      setFilteredAuction(
        auctions?.filter(
          (auction) =>
            auction?.category?.toLowerCase() === filterFor?.toLowerCase()
        )
      );
    }
  }, [auctions, filterFor, urlId]);

  const handleWindowResize = (e) => {
    if (window.innerWidth > 1440) {
      setItemsInRow(4);
    } else if (window.innerWidth >= 1000) {
      setItemsInRow(4);
    } else if (window.innerWidth < 600) {
      setItemsInRow(2);
    } else if (window.innerWidth < 700) {
      setItemsInRow(3);
    } else if (window.innerWidth < 900) {
      setItemsInRow(3);
    }
  };

  if (!filteredAuction?.length) return null;

  const renderSliderContent = () => {
    const left = [];
    const mid = [];
    const right = [];

    for (let i = 0; i < itemsInRow; i++) {
      if (sliderHasMoved) {
        if (lowestVisibleIndex + i - itemsInRow < 0) {
          left.push(totalItems - itemsInRow + lowestVisibleIndex + i);
        } else {
          left.push(i + lowestVisibleIndex - itemsInRow);
        }
      }

      if (i + lowestVisibleIndex >= totalItems) {
        mid.push(i + lowestVisibleIndex - totalItems);
      } else {
        mid.push(i + lowestVisibleIndex);
      }

      if (i + lowestVisibleIndex + itemsInRow >= totalItems) {
        right.push(i + lowestVisibleIndex + itemsInRow - totalItems);
      } else {
        right.push(i + lowestVisibleIndex + itemsInRow);
      }
    }

    const indexToDisplay = [...left, ...mid, ...right];

    if (sliderHasMoved) {
      const trailingIndex =
        indexToDisplay[indexToDisplay.length - 1] === totalItems - 1
          ? 0
          : indexToDisplay[indexToDisplay.length - 1] + 1;
      const leadingIndex =
        indexToDisplay[0] === 0 ? totalItems - 1 : indexToDisplay[0] - 1;

      indexToDisplay.unshift(leadingIndex);
      indexToDisplay.push(trailingIndex);
    }

    const sliderContents = [];
    for (let index of indexToDisplay) {
      sliderContents.push(
        <SliderItem
          auction={filteredAuction[index]}
          key={Math.random() * 83219321}
          // key={`${filteredAuction[index]?.id}-${index * 10} `}
          width={100 / itemsInRow}
        />
      );
    }

    if (!sliderHasMoved) {
      for (let i = 0; i < itemsInRow; i++) {
        sliderContents.unshift(
          <div
            className="slider-item"
            style={{ width: `${100 / itemsInRow}%` }}
            key={i}
          />
        );
      }
    }

    return sliderContents;
  };

  return (
    <div className="slider-carou">
      <h2 className="h2" style={{ color: "" }}>
        {hideHeading ? null : filterFor}
      </h2>
      <InfiniteCarousel
        breakpoints={[
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1150,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ]}
        dots={false}
        showSides={true}
        sidesOpacity={0.5}
        sideSize={0.01}
        slidesToScroll={1} // Adjust the number of items to scroll as needed
        slidesToShow={4} // Adjust the initial number of items shown as needed
        scrollOnDevice={true}
      >
        {filteredAuction.map((auction, index) => (
          <SliderItem
            auction={auction}
            key={Math.random() * 83219321}
            // key={`${filteredAuction[index]?.id}-${index * 10} `}
            width={100 / itemsInRow}
          />
        ))}
      </InfiniteCarousel>
    </div>
    // <div className="slider">
    //   {sliderHasMoved && itemsInRow >= 3 && (
    //     <SliderControl arrowDirection={"left"} onClick={handlePrev} />
    //   )}
    //   <h2 style={{ color: "black" }}>{filterFor}</h2>
    //   <div className="slider-content" style={style}>
    //     {renderSliderContent()}
    //   </div>
    //   {itemsInRow >= 3 && filteredAuction.length >= 3 && (
    //     <SliderControl arrowDirection={"right"} onClick={handleNext} />
    //   )}
    // </div>
  );
};

export default Slider;
