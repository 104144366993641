import React, { useState, useRef, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import "../../styles/tagsStyles.css";
import Dropdown from "../Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../Tooltip";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../config/firebaseSetup";
import { useTranslation } from "react-i18next";

const Tags = ({ tags, setTags, clearErrors }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation(["auctionForm", "errors"]);
  const tagInputRef = useRef(null);
  const predefinedTags = ["React", "JavaScript", "CSS", "HTML"];
  const [tagsFromFirebase, setTagsFromFirebase] = useState([]);

  const removeTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
    setValue("tags", newTags);
  };

  const addPredefinedTag = (predefinedTag) => {
    if (!tags.includes(predefinedTag)) {
      const newTags = [...tags, predefinedTag];
      setTags(newTags);
      setValue("tags", newTags);
    }
  };

  useEffect(() => {
    if (tags?.length) {
      clearErrors("tags");
    }
  }, [tags]);

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      const newTags = [...tags, val];
      setTags(newTags);
      setValue("tags", newTags);
      tagInputRef.current.value = null;
    } else if (e.key === "Backspace" && !val) {
      removeTag(tags.length - 1);
    }
  };

  // const fetchTags = async () => {
  //   try {
  //     const querySnapshot = await getDocs(collection(db, "tags"));
  //     const data = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     // Use 'data' as needed (e.g., set it to state in a React component)
  //     // console.log(data);
  //     setTagsFromFirebase(data);
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //   }
  // };
  const fetchTags = async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "tags"), where("status", "==", "approved"))
      );
      const approvedTags = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Use 'approvedTags' as needed
      // For instance, you can set it to state in a React component
      setTagsFromFirebase(approvedTags);
    } catch (error) {
      console.error("Error fetching approved tags: ", error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <div className="input-tag">
      <ul className="input-tag__tags">
        <div>
          <div className="fx">
            <h2>{t("selectedTags")}</h2>
            <Tooltip
              text={
                "You can also request for custom tags in your Profile under Request Tags section"
              }
            >
              <FontAwesomeIcon color="#457b9d" icon={faInfoCircle} />
            </Tooltip>
          </div>

          <div className="tags-list">
            {tags.length === 0 ? (
              <h2 style={{ color: "#adb5bd", fontSize: ".8rem" }}>
                Please select tags for your auction
              </h2>
            ) : (
              tags.map((tag, i) => (
                <li key={tag} className="li-tag">
                  {tag}
                  <button type="button" onClick={() => removeTag(i)}>
                    <FontAwesomeIcon icon={faClose} />
                  </button>
                </li>
              ))
            )}
          </div>
          <li className="input-tag__tags__input">
            <input
              type="text"
              onKeyDown={inputKeyDown}
              ref={tagInputRef}
              disabled
              {...register("tags", {
                validate: (value) => {
                  return value && value.length > 0
                    ? undefined
                    : "At least one tag is required";
                },
              })}
            />
          </li>
          <div className="card-form">
            <div className="pcf">
              <select
                className="month-input"
                id="cardMonth"
                // value={cardMonth}
                onChange={(e) => addPredefinedTag(e.target.value)}
                // ref={(node) => (this.monthInput = node)}
              >
                <option value="0" disabled>
                  Tags
                </option>
                {tagsFromFirebase.map((tag) => (
                  <option
                    key={tag.id}
                    value={tag.name}
                    // disabled={
                    //   cardYear === CURRENT_YEAR &&
                    //   Number(monthKey) <= CURRENT_MONTH
                    // }
                  >
                    {tag.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {errors.tags && (
            <span className="inputErrors">
              {t(errors.tags.message, { ns: "errors" })}
            </span>
          )}
        </div>
      </ul>
    </div>
  );
};

export default Tags;
