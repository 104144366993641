import ReactDOM from "react-dom";
import "../styles/modalStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const Modal = ({
  children,
  onCancel,
  onAgree,
  cancelText,
  successText,
  icon,
}) => {
  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="modal-container"
    >
      <button className="close-modal" onClick={onCancel}>
        <FontAwesomeIcon icon={faClose} />
      </button>
      <div className="modal-icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="modal-bg"></div>
      <div className="modal-content">{children}</div>
      <div className="a-center-div">
        <button onClick={onAgree} className="modal-success a-center-div">
          <span>{successText}</span>
        </button>
      </div>
      {/* <div className="modal-btns">
        <button onClick={onAgree} className="success">
          {successText}
        </button>
        <button onClick={onCancel} className="cancel">
          {cancelText}
        </button>
      </div> */}
    </motion.div>,
    document.querySelector("#modal")
  );
};

export default Modal;
