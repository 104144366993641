import React from "react";
import { motion } from "framer-motion";
import ReactDOM from "react-dom";
import "../../styles/formModalStyles.css";
import AuctionForm from "./AuctionForm";
import { useState } from "react";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../config/firebaseSetup";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

const DuplicateAuction = ({
  onClose,
  editAuction,
  getAllAuctions,
  id,
  auctionStatus,
}) => {
  const [duplicate, setEdit] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const defaultValues = {
    // auctionReservePrice: editAuction.auctionReservePrice,
    auctionTitle: editAuction.auctionTitle,
    auctionTitleItalian: editAuction.auctionTitleItalian,
    descriptionEnglish: editAuction.descriptionEnglish,
    descriptionItalian: editAuction.descriptionItalian,
    // endDate: editAuction.endDate,
    images: editAuction.images,
    // startDate: editAuction.startDate,
    tags: editAuction.tags,
    // auctionStartingPrice: editAuction.auctionStartingPrice,
    // startTime: editAuction.startTime,
    // endTime: editAuction.endTime,
    category: editAuction.category,
    subcategory: editAuction.subcategory,
    // tag: editAuction.tag,
  };

  const currentUser = auth.currentUser;

  const generateRandomTag = (length) => {
    const characters = uuid();
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  };

  const handleDupSubmit = async (formData) => {
    // console.log(formData);
    const uniquetag = generateRandomTag(4);

    try {
      // Reference to the "Auction" collection
      const auctionsColRef = collection(db, "Auction");
      const endDateTime = new Date(`${formData.endDate}T${formData.endTime}`);
      const startDateTime = new Date(
        `${formData.startDate}T${formData.startTime}`
      );
      const endDateAndTime = Timestamp.fromDate(endDateTime);
      const startDateAndTime = Timestamp.fromDate(startDateTime);

      // Create a new document with the entire formData
      // console.log(formData);
      const docRef = await addDoc(auctionsColRef, {
        created: serverTimestamp(),
        ...formData,
        status: "pending",
        uid: currentUser.uid,
        userEmail: currentUser.email,
        tag: uniquetag,
        serverEndTimeForAuction: endDateAndTime,
        serverStartTimeForAuction: startDateAndTime,
      });

      // const auctionDocRef = doc(db, "Auction", editAuction.id);
      // await updateDoc(auctionDocRef, {
      //   ...formData,
      //   status: "pending",
      // });
      toast.success("Auction Duplicated successfully!");
      getAllAuctions();
      setShowLoader(false);
      onClose();
    } catch (error) {
      setShowLoader(false);
      console.log(error);
      toast.error("Error duplicated auction:");
    }
  };

  const handleDraft = async (draftData) => {
    setShowLoader(true);
    const uniquetag = generateRandomTag(4);
    try {
      // Reference to the "Auction" collection
      const auctionsColRef = collection(db, "Auction");
      const currentUser = auth.currentUser;
      // Create a new document with the entire draftData
      const docRef = await addDoc(auctionsColRef, {
        created: serverTimestamp(),
        uid: currentUser.uid,
        status: "draft",
        ...draftData,
        tag: uniquetag,
        tags: draftData.tags ? draftData.tags : [],
      });
      // console.log(draftData);
      toast.success("Auction Drafted Successfully");
      setShowLoader(false);
      if (getAllAuctions) {
        getAllAuctions();
      }

      // console.log("Document written with ID:", docRef.id);
    } catch (error) {
      // console.error("Error adding document: ", error);
      // console.log(draftData);

      toast.error("Error Drafting Auctions");
      setShowLoader(false);
      // onCancel();
    }
  };

  const updateDraft = async (formData) => {
    setShowLoader(true);
    try {
      // Reference to the "Auction" collection
      const auctionDocRef = doc(db, "Auction", id);

      // Check if the document exists before updating or adding it
      const docSnapshot = await getDoc(auctionDocRef);
      const uniquetag = generateRandomTag(4);

      if (docSnapshot.exists()) {
        // Update the existing document
        // console.log("yes");
        await updateDoc(auctionDocRef, {
          ...formData,
          subcategory: formData.subcategory ? formData.subcategory : "",
          tags: formData.tags ? formData.tags : [],
          updated: serverTimestamp(), // Add a field to track updates
          tag: uniquetag,
        });
        toast.success("Auction Updated Successfully");
      }

      setShowLoader(false);
      if (getAllAuctions) {
        getAllAuctions();
      }

      // console.log("Document written/updated with ID:", docId);
    } catch (error) {
      console.error("Error updating/adding document: ", error);
      // console.log(draftData);

      toast.error("Error Drafting/Updating Auctions");
      setShowLoader(false);
      // onCancel();
    }
  };

  return ReactDOM.createPortal(
    <motion.div
      className="formModal-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="formModal-content">
        <h2>Duplicate Your Auction</h2>
        <button onClick={onClose} className="cancel-auction-creation">
          Close
        </button>
      </div>
      <AuctionForm
        handleSubmit={handleDupSubmit}
        defaultValues={defaultValues}
        onCancel={onClose}
        duplicate={duplicate}
        showLoader={showLoader}
        setShowLoader={setShowLoader}
        handleDraft={handleDraft}
        updateDraft={updateDraft}
        auctionStatus={editAuction.status}
      />
    </motion.div>,
    document.querySelector("#auctionDupFormModal")
  );
};

export default DuplicateAuction;
