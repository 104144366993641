import { isFormInvalid } from "../utils/isFormValid";
import { findInputError } from "../utils/findInputError";
import { useFormContext } from "react-hook-form";
import "../styles/inputFieldStyles.css";
import { useTranslation } from "react-i18next";
import Tooltip from "./Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const InputField = ({
  icon,
  type,
  name,
  id,
  placeholder,
  // value,
  // setValue,
  validationRules,
  multiline,
  label,
  disabled,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  const { t } = useTranslation(["inputFields", "errors"]);
  // coninputErrors.error.message));
  return (
    <div className="input-field">
      {label && <label>{t(`inputFields:${name}`)}</label>}

      <div className={`input-field-container ${isInvalid && "field-errors"}`}>
        {/* {icon && <img src={icon} alt="icon" />} */}
        {multiline ? (
          <textarea
            style={{ paddingLeft: icon ? "" : "13px" }}
            id={id}
            type={type}
            className={"text-area"}
            placeholder={placeholder}
            disabled={disabled}
            {...register(name, validationRules)}
          ></textarea>
        ) : (
          <input
            style={{ paddingLeft: icon ? "" : "13px" }}
            type={type}
            name={name}
            id={id}
            placeholder={placeholder}
            icon={icon}
            disabled={disabled}
            autoComplete="new-password"
            min={0}
            {...register(name, validationRules)}
          />
        )}
      </div>
      {isInvalid && (
        <h2 className="input-field-error">
          {t(`${inputErrors.error.message}`, { ns: "errors" })}
        </h2>
      )}
    </div>
  );
};

export default InputField;
