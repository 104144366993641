import { useState, useEffect, useRef } from "react";
import "../styles/dropdownSelectStyles.css";
import { useTranslation } from "react-i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";

function DropdownSelect({
  options,
  heading,
  className,
  toggleActive,
  setOptions,
}) {
  const { t } = useTranslation("dropdownSelect");
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleDocumentClick(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    }

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  function toggleDropdown() {
    setIsActive(!isActive);
    if (toggleActive) {
      toggleActive();
    }
  }

  function handleOptionClick(option, index) {
    setSelectedOption(option);
    if (setOptions) {
      setOptions(option);
    }
    setIsActive(false);
    if (toggleActive) {
      toggleActive();
    }
  }
  return (
    <div className="dropdown-select-container">
      <h3>{heading}</h3>
      <div
        className={`wrapper-dropdown ${isActive ? "active" : ""} ${className}`}
        ref={dropdownRef}
        onClick={toggleDropdown}
      >
        <div className={`${selectedOption.id && "flx"}`}>
          {selectedOption.id && (
            <span
              style={{ fontSize: "30px" }}
              className={`fi fi-${selectedOption.id.toLowerCase()} fis`}
            />
          )}
          <span>{t(selectedOption.label)}</span>
        </div>
        <ul className="dropdown">
          {options.map((option, index) => (
            <li
              className="flx"
              key={index}
              onClick={() => handleOptionClick(option, index)}
            >
              {option.id && (
                <span
                  style={{ fontSize: "30px" }}
                  className={`fi fi-${option.id.toLowerCase()} fis`}
                />
              )}
              {t(option.label)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default DropdownSelect;
