import { useEffect, useState } from "react";
import Modal from "../Modal";
import Overlay from "../Overlay";
import ReportModal from "../ReportBidModal";
import "../../styles/userAuctionsStyles.css";
import CreateAuction from "./CreateAuction";
import EditAuction from "./EditAuction";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../config/firebaseSetup";
import ImageSlider from "../ImageSlider";
import { toast } from "react-toastify";
import Loading from "../Loader";
import IconBtn from "./IconBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faClock,
  faExclamationCircle,
  faFileAlt,
  faGavel,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import DuplicateAuction from "./DublicateAuction";
import { useTranslation } from "react-i18next";
import EditAuctionRequestForm from "./EditAuctionRequestForm";
import { AnimatePresence, motion } from "framer-motion";
import { deleteObject, getStorage, ref } from "firebase/storage";
import i18n from "../../i18n/i18n";

const AuctionCard = ({
  auction,
  id,
  setAuctionId,
  setShowDeleteModal,
  setSelectedAuctionId,
  setShowEditFormModal,
  setShowDuplicateFormModal,
  setSelectAuctionToEdit,
  setShowBidReportModal,
  setSelectedAuction,
}) => {
  const currentLanguage = i18n.language;

  return (
    <div className="product-card">
      {/* <div className="badge">Hot</div> */}
      <div className="product-tumb">
        {auction.images ? (
          <img src={auction?.images[0]?.fileimage} alt="" />
        ) : (
          <img src="" alt="" />
        )}
      </div>
      <div className="product-details">
        <span className="product-catagory">
          {auction.tags.map((tag) => (
            <span style={{ marginRight: "5px" }} key={tag}>
              {tag}
            </span>
          ))}
        </span>
        <h4>
          {currentLanguage === "it"
            ? auction.auctionTitleItalian
            : auction.auctionTitle}
          {/* {auction.auctionTitle}{" "} */}
          <span style={{ color: "#bebdbd", marginLeft: "10px" }}>#</span>{" "}
          {auction.tag}
        </h4>
        {/* <p> */}
        {/* <p
          dangerouslySetInnerHTML={{
            __html: auction.descriptionEnglish,
          }}
        /> */}
        {/* {auction.descriptionEnglish}</p> */}
        <div className="product-price">
          <span>${auction.auctionReservePrice}</span>
        </div>
        <div className="product-bottom-details">
          {/* <div className="product-price">
            <span>${auction.auctionReservePrice}</span>
          </div> */}
          <div></div>
          <div className="product-links">
            {auction.status.toLowerCase() === "pending".toLowerCase() ||
            auction.status.toLowerCase() === "draft".toLowerCase() ? (
              <>
                <button
                  className="auctionIsApproved-btn"
                  style={{
                    color:
                      auction.status.toLowerCase() === "draft".toLowerCase()
                        ? "#2b2d42"
                        : "#ddd92a",
                  }}
                >
                  {auction.status.toLowerCase() === "draft".toLowerCase()
                    ? "Draft"
                    : "Pending"}
                </button>
                <div className="button-div">
                  <button
                    className="button-cta with-hover"
                    onClick={() => {
                      setShowDuplicateFormModal(true);
                      setSelectAuctionToEdit(auction);
                      setAuctionId(id);
                    }}
                  >
                    Duplicate
                  </button>
                </div>
                {auction.status.toLowerCase() === "pending" && (
                  <div className="button-div">
                    <button
                      className="button-cta with-hover"
                      onClick={() => {
                        // setShowEditFormModal(true);
                        // setSelectAuctionToEdit(auction);
                        // setAuctionId(id);
                        setSelectAuctionToEdit(auction);
                        setAuctionId(id);
                        setShowBidReportModal(true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                )}
                {auction.status.toLowerCase() === "draft" && (
                  <div className="button-div">
                    <button
                      className="button-cta with-hover"
                      onClick={() => {
                        setShowEditFormModal(true);
                        setSelectAuctionToEdit(auction);
                        setAuctionId(id);
                        // setSelectAuctionToEdit(auction);
                        // setAuctionId(id);
                        // setShowBidReportModal(true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                <button className="auctionIsApproved-btn">
                  {auction.status.toLowerCase() === "closed".toLowerCase()
                    ? "Closed"
                    : "Approved"}
                </button>
                <div className="button-div">
                  <button
                    className="button-cta with-hover "
                    onClick={() => {
                      setShowDuplicateFormModal(true);
                      setSelectAuctionToEdit(auction);
                      setAuctionId(id);
                    }}
                  >
                    Duplicate
                  </button>
                </div>

                <div className="button-div">
                  <button
                    className="button-cta with-hover"
                    onClick={() => {
                      // setShowDuplicateFormModal(true);
                      setSelectAuctionToEdit(auction);
                      setAuctionId(id);
                      setShowBidReportModal(true);
                    }}
                  >
                    Edit
                  </button>
                </div>
              </>
            )}
            {auction?.status?.toLowerCase() === "draft".toLowerCase() && (
              <button
                className="delete-btn"
                onClick={() => {
                  setShowDeleteModal(true);
                  setSelectedAuction(auction);
                }}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const UserAuctions = ({ userData }) => {
  const { t } = useTranslation("userAuctions");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateFormModal, setShowCreateFormModal] = useState(false);
  const [showDuplicateFormModal, setShowDuplicateFormModal] = useState(false);
  const [showEditFormModal, setShowEditFormModal] = useState(false);
  const [auctions, setAuctions] = useState(null);
  const [selectedAuctionId, setSelectedAuctionId] = useState(null);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const [selectAuctionToEdit, setSelectAuctionToEdit] = useState(null);
  const [activeIcon, setActiveIcon] = useState(faCheckCircle);
  const [auctionId, setAuctionId] = useState(null);
  const [showBidReportModal, setShowBidReportModal] = useState(false);
  const [showLoader, setLoader] = useState(false);

  // console.log(auctionId);
  // const getAllAuctions = async () => {
  //   try {
  //     const groceriesColRef = collection(db, "Auction");
  //     const q = query(groceriesColRef, orderBy("created", "desc"));
  //     const querySnapshot = await getDocs(q);

  //     const auctionsData = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));

  //     setAuctions(auctionsData);
  //   } catch (error) {
  //     console.error("Error getting documents: ", error);
  //     toast.error("Error getting documents");
  //     // Handle the error appropriately
  //   }
  // };
  const user = auth.currentUser;
  const getAllAuctions = async () => {
    // console.log(user.uid);
    try {
      if (user) {
        const groceriesColRef = collection(db, "Auction");
        const q = query(
          groceriesColRef,
          where("uid", "==", user.uid), // Filter auctions by the current user's uid
          orderBy("created", "desc")
        );
        const querySnapshot = await getDocs(q);

        const auctionsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setAuctions(auctionsData);
      } else {
        setAuctions([]);
      }
    } catch (error) {
      // console.error("Error getting documents: ", error);
      toast.error("Error getting documents");
      setAuctions([]);
      // Handle the error appropriately
    }
  };

  const handleDelete = async () => {
    // console.log(selectedAuctionId);
    try {
      setLoader(true);
      if (selectedAuction) {
        const auctionDocRef = await doc(db, "Auction", selectedAuction.id);
        await deleteDoc(auctionDocRef);
        // console.log("Document successfully deleted!");
        const storage = getStorage();

        selectedAuction.images.forEach(async (image) => {
          try {
            const imageRef = ref(storage, `${image.bucket}/${image.filename}`);
            await deleteObject(imageRef);
          } catch (error) {}
        });
        setLoader(false);
        setShowDeleteModal(false);
        toast.success("Auction successfully deleted!");
        // Fetch the updated list of auctions after deletion
        getAllAuctions();
      }
    } catch (error) {
      setLoader(false);
      toast.error("Error deleting auction");
      // console.error("Error deleting document: ", error);
    }
  };

  useEffect(() => {
    getAllAuctions();
  }, []);

  useEffect(() => {
    if (
      showCreateFormModal ||
      showDeleteModal ||
      showEditFormModal ||
      showDuplicateFormModal
    ) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  }, [
    showCreateFormModal,
    showDeleteModal,
    showEditFormModal,
    showDuplicateFormModal,
  ]);

  const handleIconClick = (iconName) => {
    setActiveIcon(iconName);
  };

  if (!auctions) {
    return <Loading />;
  }

  const pendingAuctions = auctions?.filter(
    (auction) => auction?.status.toLowerCase() === "pending".toLowerCase()
  );
  const approvedAuctions = auctions?.filter(
    (auction) => auction?.status.toLowerCase() === "approved".toLowerCase()
  );
  const closedAuctions = auctions?.filter(
    (auction) => auction?.status.toLowerCase() === "closed".toLowerCase()
  );
  const draftedAuctions = auctions?.filter(
    (auction) => auction?.status.toLowerCase() === "draft".toLowerCase()
  );

  // console.log(auctions);
  if (!user) return <span>No USER</span>;
  return (
    <div className="user-auctions-display">
      {showLoader && <Loading />}
      <div className="create-new-auction">
        <IconBtn
          onClick={() => {
            // setActiveIcon(faGavel);
            setShowCreateFormModal(true);
          }}
          iconName={faGavel}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#e2cfea" }}>
            <FontAwesomeIcon icon={faGavel} color="purple" />
          </div>
          <span>{t("create")}</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faCheckCircle);
          }}
          iconName={faCheckCircle}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#c7f9cc" }}
          >
            <FontAwesomeIcon icon={faCheckCircle} color="#25a18e" />
          </div>
          <span>{t("approved")}</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faClock);
          }}
          iconName={faClock}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#fff2b2" }}>
            <FontAwesomeIcon icon={faClock} color="#ddd92a" />
          </div>
          <span>{t("pending")}</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faLock);
          }}
          iconName={faLock}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#e639476b" }}
          >
            <FontAwesomeIcon icon={faLock} color="#ef233c" />
          </div>
          <span>{t("closed")}</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faFileAlt);
          }}
          iconName={faFileAlt}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#ced4da" }}>
            <FontAwesomeIcon icon={faFileAlt} color="#2b2d42" />
          </div>
          <span>{t("draft")}</span>
        </IconBtn>
      </div>

      <div className="user-auction-cards">
        {activeIcon === faCheckCircle &&
          (approvedAuctions.length > 0 ? (
            approvedAuctions.map((auction, index) => (
              <motion.div
                key={auction.id}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <AuctionCard
                  id={auction.id}
                  setAuctionId={setAuctionId}
                  auction={auction}
                  setSelectedAuctionId={setSelectedAuctionId}
                  setShowDeleteModal={setShowDeleteModal}
                  setShowEditFormModal={setShowEditFormModal}
                  setSelectAuctionToEdit={setSelectAuctionToEdit}
                  setShowDuplicateFormModal={setShowDuplicateFormModal}
                  setShowBidReportModal={setShowBidReportModal}
                />
              </motion.div>
            ))
          ) : (
            <h3 className="hed">No Auctions Approved Yet</h3>
          ))}
        {activeIcon === faClock &&
          (pendingAuctions.length > 0 ? (
            pendingAuctions.map((auction, index) => (
              <motion.div
                key={auction.id}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <AuctionCard
                  id={auction.id}
                  key={auction.id}
                  auction={auction}
                  setAuctionId={setAuctionId}
                  setSelectedAuctionId={setSelectedAuctionId}
                  setShowDeleteModal={setShowDeleteModal}
                  setShowEditFormModal={setShowEditFormModal}
                  setSelectAuctionToEdit={setSelectAuctionToEdit}
                  setShowBidReportModal={setShowBidReportModal}
                  setShowDuplicateFormModal={setShowDuplicateFormModal}
                />
              </motion.div>
            ))
          ) : (
            <h3 className="hed">No Pending Auctions</h3>
          ))}
        {activeIcon === faFileAlt &&
          (draftedAuctions.length > 0 ? (
            draftedAuctions.map((auction, index) => (
              <motion.div
                key={auction.id}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <AuctionCard
                  id={auction.id}
                  key={auction.id}
                  auction={auction}
                  setAuctionId={setAuctionId}
                  setSelectedAuctionId={setSelectedAuctionId}
                  setSelectedAuction={setSelectedAuction}
                  setShowDeleteModal={setShowDeleteModal}
                  setShowEditFormModal={setShowEditFormModal}
                  setSelectAuctionToEdit={setSelectAuctionToEdit}
                  setShowDuplicateFormModal={setShowDuplicateFormModal}
                />
              </motion.div>
            ))
          ) : (
            <h3 className="hed">No Draft Auctions</h3>
          ))}
        {activeIcon === faLock &&
          (closedAuctions.length > 0 ? (
            closedAuctions.map((auction, index) => (
              <motion.div
                key={auction.id}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <AuctionCard
                  id={auction.id}
                  key={auction.id}
                  auction={auction}
                  setAuctionId={setAuctionId}
                  setSelectedAuctionId={setSelectedAuctionId}
                  setShowDeleteModal={setShowDeleteModal}
                  setShowEditFormModal={setShowEditFormModal}
                  setSelectAuctionToEdit={setSelectAuctionToEdit}
                  setShowDuplicateFormModal={setShowDuplicateFormModal}
                />
              </motion.div>
            ))
          ) : (
            <h3 className="hed">No Closed Auctions</h3>
          ))}
      </div>
      <AnimatePresence>
        {showCreateFormModal && (
          <CreateAuction
            userData={userData}
            onClose={() => setShowCreateFormModal(false)}
            getAllAuctions={getAllAuctions}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showEditFormModal && (
          <EditAuction
            editAuction={selectAuctionToEdit}
            getAllAuctions={getAllAuctions}
            onClose={() => setShowEditFormModal(false)}
            id={auctionId}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showDuplicateFormModal && (
          <DuplicateAuction
            editAuction={selectAuctionToEdit}
            getAllAuctions={getAllAuctions}
            onClose={() => setShowDuplicateFormModal(false)}
            id={auctionId}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showBidReportModal && (
          <>
            <Overlay
              onClose={() => {
                setShowBidReportModal(false);
              }}
            />
            <ReportModal>
              <EditAuctionRequestForm
                auction={selectAuctionToEdit}
                auctionId={auctionId}
                // user={userData}
                setModal={setShowBidReportModal}
              />
            </ReportModal>
          </>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showDeleteModal && (
          <>
            <Overlay
              onClose={() => {
                setShowDeleteModal(false);
              }}
            />
            <Modal
              onCancel={() => {
                setShowDeleteModal(false);
              }}
              icon={faExclamationCircle}
              successText={"Delete Auction"}
              cancelText={"Cancel"}
              onAgree={handleDelete}
            >
              <h2>Delete Auction</h2>
              <p>Are you sure you want to delete?</p>
            </Modal>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserAuctions;
