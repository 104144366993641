import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../config/firebaseSetup";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import i18n from "../i18n/i18n";

const UserNotificationModal = ({ user, notifications }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.5 },
    },
  };

  const childVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const markNotification = async (notiId, readStatus) => {
    const userDocRef = doc(db, "users", user.uid);
    try {
      await updateDoc(userDocRef, {
        notifications: notifications.map((noti) => {
          if (noti.id === notiId) {
            return { ...noti, read: readStatus };
          }
          return noti;
        }),
      });
    } catch (error) {
      console.error("Error updating notification status:", error);
    }
  };

  const deleteNotification = async (notiId) => {
    try {
      const updatedNotifications = notifications.filter(
        (noti) => noti.id !== notiId
      );
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, { notifications: updatedNotifications });
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const markAllAsRead = async () => {
    const userDocRef = doc(db, "users", user.uid);
    try {
      await updateDoc(userDocRef, {
        notifications: notifications.map((noti) => ({
          ...noti,
          read: true,
        })),
      });
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const deleteAllNotifications = async () => {
    const userDocRef = doc(db, "users", user.uid);
    try {
      await updateDoc(userDocRef, {
        notifications: [],
      });
    } catch (error) {
      console.error("Error deleting all notifications:", error);
    }
  };

  if (!user) {
    return (
      <motion.h2
        className="user-noti-modal"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={containerVariants}
        style={{ color: "black" }}
      >
        Please Login
      </motion.h2>
    );
  }

  if (!notifications.length) {
    return (
      <motion.h2
        className="user-noti-modal"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={containerVariants}
        style={{ color: "black" }}
      >
        No Notifications
      </motion.h2>
      // <motion.div
      //   className="user-noti-modal flex-msg"
      //   initial="hidden"
      //   animate="visible"
      //   exit="exit"
      //   variants={containerVariants}
      // >
      //   <div className="spinner_container-2" aria-label="Loading...">
      //     <i className="spinner_item"></i>
      //     <i className="spinner_item"></i>
      //   </div>
      // </motion.div>
    );
  }

  const renderNotification = notifications?.map((noti, index) => {
    const currentLang = i18n.language;
    const title =
      noti && noti.auctionData && currentLang === "it"
        ? noti?.auctionData?.auctionTitleIt
        : noti?.auctionData?.auctionTitleIt;

    // Replace spaces and special characters with hyphens (-)
    const formattedTitle = title
      ?.replace(/\s+/g, "-")
      .replace(/\W/g, "-")
      .replace(/-+/g, "-");

    const encodedTitle = encodeURIComponent(formattedTitle);
    const encodedId = encodeURIComponent(noti?.auctionData?.auctionId);

    const link = `/product/${
      currentLang === "it" ? "it" : "en"
    }/${encodedTitle}/${encodedId}`;
    const isEmpty = (obj) => Object.keys(obj).length === 0;

    return (
      <motion.div
        key={index}
        className={`msg-user ${noti.read ? "read" : "unread"}`}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={childVariants}
      >
        <h2>
          {!noti.read ? <span className="new-noti">New</span> : null}
          {user.email}
        </h2>
        <p>{noti.message}</p>
        {noti && noti.auctionData && !isEmpty(noti.auctionData) ? (
          <Link target="_blank" to={link}>
            View
          </Link>
        ) : null}
        <div>
          <div>
            {!noti.read ? (
              <button
                className="noti-button"
                onClick={() => markNotification(noti.id, true)}
              >
                Mark as read
              </button>
            ) : (
              <button
                className="noti-button"
                onClick={() => markNotification(noti.id, false)}
              >
                Mark as unread
              </button>
            )}
            <button
              onClick={() => deleteNotification(noti.id)}
              className="noti-delete-btn"
            >
              Delete
            </button>
          </div>
          <div className="time-noti">
            <p>{formatTimestamp(noti.timestamp)}</p>
          </div>
        </div>
      </motion.div>
    );
  });

  // const hasUnreadMessages = notifications.some(
  //   (notification) => !notification.read
  // );

  return (
    <motion.div
      className="user-noti-modal"
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
    >
      <div className="markAllAsReadAndDelete">
        <button onClick={markAllAsRead}>Mark all as read</button>
        <button onClick={deleteAllNotifications}>Delete All</button>
      </div>
      {renderNotification}
    </motion.div>
  );
};

export default UserNotificationModal;

// Function to format the timestamp// Function to format the timestamp
const formatTimestamp = (timestamp) => {
  if (timestamp) {
    const date = timestamp.toDate();
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);
    const formattedDate = date.toLocaleDateString("en-GB", dateOptions);
    return `${formattedTime} ${formattedDate}`;
  } else {
    return "";
  }
};
