import { useNavigate } from "react-router-dom";
import "../../styles/searchItemStyle.css";
import { useTranslation } from "react-i18next";
import slugify from "slugify";

const SearchItem = ({ searchedItems, setSearchActive }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const navigateHandler = (item) => {
    const title =
      currentLanguage === "it"
        ? item.auctionTitleItalian
        : item.auctionTitleItalian;

    // Replace spaces and special characters with hyphens (-)
    const formattedTitle = title.replace(/\s+/g, "-").replace(/\W/g, "-");

    // Generate a slug from the formatted title, replacing special characters with hyphens
    const slug = slugify(formattedTitle, {
      lower: true,
      remove: /[$*_+~.()'"!\-:@]/g,
    });
    const encodedTitle = encodeURIComponent(slug);
    const encodedId = encodeURIComponent(item.id);

    navigate(`/product/${currentLanguage}/${encodedTitle}/${encodedId}`);

    // navigate(`/auction-item/${item.auctionTitle}/${item.id}`);
    setSearchActive(false);
  };

  const renderList = searchedItems.map((item) => (
    <div
      key={item.id}
      className="searchItem"
      onClick={() => navigateHandler(item)}
    >
      <img src={item.images[0].fileimage} alt="" />
      <h2>
        {i18n.language === "it" ? item.auctionTitleItalian : item.auctionTitle}{" "}
        <span style={{ marginLeft: "10px", color: "#a5a5a5" }}># </span>
        {item.tag}
      </h2>
      {/* <p>Starts on {item.startDate}</p> */}
      {/* <p>Ends on {item.endDate}</p> */}
    </div>
  ));

  return <div className="searchItemsContainer">{renderList}</div>;
};

export default SearchItem;
