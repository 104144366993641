import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Overlay from "./Overlay";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { motion } from "framer-motion";
import DropdownSelect from "./DropdownSelect";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { DateRangePicker } from "react-date-range";
import PriceRangeDropdown from "./PricerangeDropdown";

const FiltersModal = ({
  onClose,
  setPriceRange,
  applyPriceRangeFilter,
  setOptions,
  selectionRange,
  handleSelect,
  applyFilters,
  setShowDatePicker,
  showDatePicker,
  options,
  datePickerRef,
}) => {
  const { t } = useTranslation("dropdownSelect");

  useEffect(() => {
    handleWindowResize(window);
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const filterBy = [
    { label: "All" },
    { label: "Closed" },
    { label: "Coming Soon" },
    { label: "No Bids" },
  ];

  const handleWindowResize = (e) => {
    if (window.innerWidth > 1023) {
      onClose();
    }
  };

  return (
    <div className="filters-modals">
      <Overlay onClose={onClose} />
      <div className="filters-modals-content">
        <div className="icon-modal">
          <h2>Filters</h2>
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            onClick={onClose}
            icon={faClose}
          />
        </div>
        <div className="tagAuction-container">
          <div className="dropdown-overflow">
            <DropdownSelect
              options={filterBy}
              heading={t("filterBy")}
              className={"wrapper-dropdown-width"}
              setOptions={(selectedOption) =>
                setOptions({ ...options, filterBy: selectedOption })
              }
            />
            {/* <div className="date-picker-filter">
              <h2
                className="wrapper-dropdown"
                onClick={() => setShowDatePicker(!showDatePicker)}
              >
                By Date
              </h2>
              {showDatePicker && (
                <div className="date-picker-filter-dp" ref={datePickerRef}>
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showPreview={false}
                    minDate={
                      new Date(new Date().getTime() + 1 * 60 * 60 * 1000)
                    }
                  />
                </div>
              )}
            </div> */}
            <PriceRangeDropdown
              setPriceRange={setPriceRange}
              filterByPriceRange={applyPriceRangeFilter}
              heading={t("priceRange")}
              onClose={onClose}
            />
            <Button
              onClick={() => {
                applyFilters();
                onClose();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersModal;
