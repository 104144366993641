import "../styles/buttonStyles.css";

const Button = ({ children, onClick, style, className }) => {
  return (
    <button
      onClick={onClick}
      style={style}
      className={`button-default-style ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
