import React, { useState, useEffect } from "react";
import { doc, getDoc, collection } from "firebase/firestore";
import { auth, db } from "../../config/firebaseSetup";
import Loading from "../Loader";
import AuctionItem from "../AuctionItem";
import IconBtn from "./IconBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faClock,
  faExclamationCircle,
  faGavel,
  faLock,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { format, isPast } from "date-fns";

const UserBids = ({ heading }) => {
  const [userBids, setUserBids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIcon, setActiveIcon] = useState(faClock);
  const [approvedBids, setApprovedBids] = useState([]);
  const [wonBids, setWonBids] = useState([]);
  const [userOutBids, setUserOutBids] = useState([]);
  const [closedBids, setClosedBids] = useState([]);

  const getUserAuctions = async (userDoc, auctionsCollectionRef) => {
    try {
      const userWonBidsDetails = [];
      const userOutbids = [];

      if (userDoc.exists()) {
        const userWonBids = userDoc.data().wonBids || [];
        const outbids = userDoc.data().outBids || [];

        for (const bidId of userWonBids) {
          const bidDocRef = doc(auctionsCollectionRef, bidId);
          const bidSnapshot = await getDoc(bidDocRef);

          if (bidSnapshot.exists()) {
            userWonBidsDetails.push({
              id: bidSnapshot.id,
              ...bidSnapshot.data(),
            });
          }
        }

        for (const bidId of outbids) {
          const bidDocRef = doc(auctionsCollectionRef, bidId);
          const bidSnapshot = await getDoc(bidDocRef);

          if (bidSnapshot.exists()) {
            userOutbids.push({
              id: bidSnapshot.id,
              ...bidSnapshot.data(),
            });
          }
        }
      }
      setWonBids(userWonBidsDetails);
      setUserOutBids(userOutbids);
    } catch (err) {
      setWonBids([]);
    }
  };

  const fetchUserBids = async (userId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      const userBidsDetails = [];

      if (userDoc.exists()) {
        const myBids = userDoc.data().myBids || [];
        const userWonBids = userDoc.data().wonBids || [];
        const userOutbids = userDoc.data().outBids || [];

        const auctionsCollectionRef = collection(db, "Auction");
        if (userWonBids.length || userOutbids.length) {
          getUserAuctions(userDoc, auctionsCollectionRef);
        }

        for (const bidId of myBids) {
          const bidDocRef = doc(auctionsCollectionRef, bidId);
          const bidSnapshot = await getDoc(bidDocRef);

          if (bidSnapshot.exists()) {
            userBidsDetails.push({
              id: bidSnapshot.id,
              ...bidSnapshot.data(),
            });
          }
        }
        // console.log("User Bids Details:", userBidsDetails);
        // Sort userBidsDetails array in descending order based on a property (e.g., bid timestamp)
        userBidsDetails.sort((a, b) => b.created - a.created);

        // console.log("User Bids Details:", userBidsDetails);

        return userBidsDetails;
      }
      return [];
    } catch (error) {
      // console.error("Error fetching user bids:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchUserBidsData = async () => {
      const user = auth.currentUser;

      if (user) {
        const userBidsData = await fetchUserBids(user.uid);
        setUserBids(userBidsData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    fetchUserBidsData();
  }, []);

  useEffect(() => {
    const fetchUserBidsData = async () => {
      const user = auth.currentUser;

      if (user) {
        const userBidsData = await fetchUserBids(user.uid);
        setUserBids(userBidsData);

        const approved = userBidsData.filter(
          (auction) => auction.status.toLowerCase() === "approved"
        );

        const closed = userBidsData.filter(
          (auction) => auction.status.toLowerCase() === "closed"
        );

        setApprovedBids(approved);
        setClosedBids(closed);

        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    fetchUserBidsData();
  }, []);

  const renderBids = () => {
    switch (activeIcon) {
      case faCheckCircle:
        return wonBids;
      case faExclamationCircle:
        return userOutBids;
      case faClock:
        return approvedBids;
      // case faLock:
      //   return closedBids;
      case faQuestionCircle:
        return userBids.filter(
          (auction) =>
            auction.status === "pending_payment" &&
            auction.lastBidderId === auth.currentUser.uid
        );
      default:
        return userBids.filter((auction) =>
          isPast(
            format(
              new Date(auction.serverEndTimeForAuction.seconds * 1000),
              "yyyy-MM-dd HH:mm:ss"
            )
          )
        );
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="scroll-view">
      <h2 className="likedAHead">My Bids</h2>
      <div className="create-new-auction">
        <IconBtn
          onClick={() => {
            setActiveIcon(faCheckCircle);
            // setAuctionStatus("won");
          }}
          iconName={faCheckCircle}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#c7f9cc" }}
          >
            <FontAwesomeIcon icon={faCheckCircle} color="#25a18e" />
          </div>
          <span>Won</span>
        </IconBtn>

        <IconBtn
          onClick={() => {
            setActiveIcon(faClock);
            // setAuctionStatus("approved");
          }}
          iconName={faClock}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#fff2b2" }}>
            <FontAwesomeIcon icon={faClock} color="#ddd92a" />
          </div>
          <span>Progress</span>
        </IconBtn>

        <IconBtn
          onClick={() => {
            setActiveIcon(faExclamationCircle);
          }}
          iconName={faExclamationCircle}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#ffcdd2" }}
          >
            <FontAwesomeIcon icon={faExclamationCircle} color="#d32f2f" />
          </div>
          <span>Out Bid</span>
        </IconBtn>

        <IconBtn
          onClick={() => {
            setActiveIcon(faLock);
            // setAuctionStatus("closed");
          }}
          iconName={faLock}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#e639476b" }}
          >
            <FontAwesomeIcon icon={faLock} color="#ef233c" />
          </div>
          <span>Ended</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faQuestionCircle);
            // setAuctionStatus("closed");
          }}
          iconName={faQuestionCircle}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#e639476b" }}
          >
            <FontAwesomeIcon icon={faQuestionCircle} color="#ef233c" />
          </div>
          <span>Payment</span>
        </IconBtn>
      </div>
      {renderBids().length ? (
        <>
          <div className="tagAuction-content">
            {/* <h2 className="likedAHead">
              {heading} ({renderBids().length})
            </h2> */}
            <div className="tagAuction-auctions">
              {renderBids().map((auction) => (
                <AuctionItem
                  auction={auction}
                  id={auction.id}
                  key={auction.id}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div style={{ marginTop: "1rem" }}>No Bids</div>
      )}
    </div>
  );
};

export default UserBids;
