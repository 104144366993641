import React, { useEffect, useRef, useState } from "react";

function TimePicker({ setValue, validate, register, value, id }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState("01");
  const [selectedMinute, setSelectedMinute] = useState("00");
  const [selectedMeridiem, setSelectedMeridiem] = useState("am");

  const prevSelectedHour = useRef(selectedHour);
  const prevSelectedMinute = useRef(selectedMinute);
  const prevSelectedMeridiem = useRef(selectedMeridiem);

  useEffect(() => {
    if (
      prevSelectedHour.current !== selectedHour ||
      prevSelectedMinute.current !== selectedMinute ||
      prevSelectedMeridiem.current !== selectedMeridiem
    ) {
      handleSelectChange();

      prevSelectedHour.current = selectedHour;
      prevSelectedMinute.current = selectedMinute;
      prevSelectedMeridiem.current = selectedMeridiem;
    }
  }, [selectedHour, selectedMinute, selectedMeridiem]);

  const togglePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectChange = () => {
    setValue(id, getTimeStringFromPicker());
  };

  const buildPicker = () => {
    // const hourOptions = [...Array(12).keys()].map((number, index) =>
    //   numberToOption(number + 1, index)
    // );
    // const minuteOptions = [...Array(12).keys()].map((number, index) =>
    //   numberToOption(number * 5, index)
    // );

    // Adjust the range of hours based on meridiem
    const hourOptions = [
      ...Array(selectedMeridiem === "am" ? 12 : 11).keys(),
    ].map((number, index) => numberToOption(number + 1, index));

    const minuteOptions = [...Array(12).keys()].map((number, index) =>
      numberToOption(number * 5, index)
    );

    return (
      <div
        style={{
          marginTop: ".5rem",
        }}
        className="time-picker"
        onClick={(e) => e.stopPropagation()}
      >
        <select
          // className="time-picker__select"
          className="month-input"
          value={selectedHour}
          onChange={(e) => {
            setSelectedHour(e.target.value);
            handleSelectChange();
          }}
          style={{
            padding: ".5rem",
          }}
        >
          {hourOptions}
        </select>
        <select
          // className="time-picker__select"
          className="month-input"
          value={selectedMinute}
          onChange={(e) => {
            setSelectedMinute(e.target.value);
            handleSelectChange();
          }}
          style={{
            padding: ".5rem",
          }}
        >
          {minuteOptions}
        </select>
        <select
          // className="time-picker__select"
          className="month-input"
          value={selectedMeridiem}
          onChange={(e) => {
            setSelectedMeridiem(e.target.value);
            handleSelectChange();
          }}
          style={{
            padding: ".5rem",
          }}
        >
          <option value="am">am</option>
          <option value="pm">pm</option>
        </select>
      </div>
    );
  };

  const getTimeStringFromPicker = () => {
    let hour = Number(selectedHour);
    if (selectedMeridiem === "pm" && hour !== 12) {
      hour += 12;
    } else if (selectedMeridiem === "am" && hour === 12) {
      hour = 0;
    }
    const minute = selectedMinute;

    return `${hour.toString().padStart(2, "0")}:${minute}`;
  };

  const numberToOption = (number, index) => {
    const padded = number.toString().padStart(2, "0");
    return (
      <option key={index} value={padded}>
        {padded}
      </option>
    );
  };

  return (
    <>
      <input
        type="text"
        className="datepicker"
        style={{ cursor: "pointer" }}
        readOnly
        onClick={togglePicker}
        value={getTimeStringFromPicker()}
        id={id}
        onChange={(e) => setValue(id, e.target.value)}
        {...register(id, {
          required: "Time is required",
          validate: validate,
        })}
      />
      {isOpen && buildPicker()}
    </>
  );
}

export default TimePicker;
