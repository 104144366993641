import { useEffect, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../config/firebaseSetup";
import "../../styles/userLikedAuctions.css";
import Loading from "../Loader";
import AuctionItem from "../AuctionItem";

const UserLikedAuctions = ({ heading }) => {
  const [likedAuctions, setLikedAuctions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLikedAuctions = async (userId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const likedAuctions = userDoc.data().likedAuctions || [];
        const auctionsCollectionRef = collection(db, "Auction");
        const likedAuctionDetails = [];

        for (const auctionId of likedAuctions) {
          const auctionDocRef = doc(auctionsCollectionRef, auctionId);
          const auctionSnapshot = await getDoc(auctionDocRef);

          if (auctionSnapshot.exists()) {
            likedAuctionDetails.push({
              id: auctionSnapshot.id,
              ...auctionSnapshot.data(),
            });
          }
        }

        // console.log("Liked Auction Details:", likedAuctionDetails);

        return likedAuctionDetails;
      }

      return [];
    } catch (error) {
      console.error("Error fetching liked auctions:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchLikedAuctions = async () => {
      const user = auth.currentUser;
      if (user) {
        const userLikedAuctions = await getLikedAuctions(user.uid);
        setLikedAuctions(userLikedAuctions);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    fetchLikedAuctions();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {likedAuctions.length ? (
        <>
          {/* {" "} */}
          <div className="tagAuction-content">
            <h2 className="likedAHead">{heading}</h2>
            <div className="liked-auctions">
              {likedAuctions
                .filter(
                  (auction) =>
                    auction.status.toLowerCase() === "approved".toLowerCase() ||
                    auction.status.toLowerCase() === "closed".toLowerCase()
                )
                .map((auction) => (
                  <AuctionItem
                    auction={auction}
                    id={auction.id}
                    key={auction.id}
                  />
                ))}
            </div>
          </div>
        </>
      ) : (
        <div>No Liked Auctions</div>
      )}
    </div>
  );
};

export default UserLikedAuctions;
