import ReactDOM from "react-dom";
// import "../styles/modalStyles.css";
import { motion } from "framer-motion";

const Modal = ({ children }) => {
  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="modal-containerAuth mcheight"
    >
      {children}
    </motion.div>,
    document.querySelector("#authModal")
  );
};

export default Modal;
