import ReactDOM from "react-dom";
import "../styles/overlayStyles.css";
import { motion } from "framer-motion";

const Overlay = ({ onClose }) => {
  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      onClick={onClose}
      className="overlay-modal"
    ></motion.div>,
    document.querySelector("#overlay")
  );
};

export default Overlay;
