import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../config/firebaseSetup";
import Button from "../Button";
import { toast } from "react-toastify";
import { useState } from "react";
import Loading from "../Loader";
import RequestSellerCard from "../cards/RequestSellerCard";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import fetchAndSendEmailTemplate from "../../utils/emailSend";

export const BecomeASeller = ({ user, getUser }) => {
  const [showLoader, setShowLoader] = useState(false);
  const methods = useForm();

  const reqToBecomeASeller = async (questions) => {
    setShowLoader(true);
    const user = auth.currentUser;

    try {
      // Reference to the specific document in the "users" collection
      const userDocRef = doc(db, "users", user.uid);
      // Update the document with isApproved: true
      await updateDoc(userDocRef, {
        status: "pending",
        isASeller: false,
        userForm: [questions],
      });
      // setShowSuspendModal(false);
      // await getUsers();
      const updatedUserSnapshot = await getDoc(userDocRef);
      const updatedUserData = updatedUserSnapshot.data();
      const updatedUserWithId = {
        ...updatedUserData,
        id: updatedUserSnapshot.id,
      };

      toast.success("Request Sent");
      // setUserDetails(updatedUserWithId);
      // axios.post("http://localhost:3002/reqToBecomeASeller", {
      //   email: "hamzakhan12778@gmail.com",
      // });
      setShowLoader(false);
      // console.log("Approved");
      fetchAndSendEmailTemplate(
        "For Seller Request",
        user,
        "https://stripe-server-production-3c2c.up.railway.app/send-email"
      );
      await getUser(user);
    } catch (error) {
      toast.error("Error sending request");
      console.log(error);
      setShowLoader(false);
    }
  };

  const populateQuestions = async (formData) => {
    let questions = {};

    if (formData["questionOne"]) {
      questions["What motivates you to sell your items on our platform?"] =
        formData["questionOne"];
    }
    if (formData["questionTwo"]) {
      questions["Are you looking to declutter and make space in your home?"] =
        formData["questionTwo"];
    }
    if (formData["questionThree"]) {
      questions[
        "Are you upgrading to newer items and need to sell your current ones?"
      ] = formData["questionThree"];
    }

    return questions;
  };

  const submitHandler = methods.handleSubmit(async (formData) => {
    const questions = await populateQuestions(formData);
    await reqToBecomeASeller(questions);
  });

  return (
    <div>
      {showLoader && <Loading />}
      <FormProvider {...methods}>
        <form
          className="billing-tab-form"
          action=""
          onSubmit={(e) => e.preventDefault()}
        >
          <RequestSellerCard
            style={{ marginTop: "0", width: "100%", maxWidth: "700px" }}
          />
          <Button onClick={submitHandler}>Request</Button>
        </form>
      </FormProvider>
    </div>
  );
};
