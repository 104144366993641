import { Link } from "react-router-dom";
import facebookLogo from "../assets/icons/facebook.svg";
import "../styles/footerStyles.css";
import { useState } from "react";
import engIcon from "../assets/icons/british-flag.svg";
import italyFlag from "../assets/icons/italy-flag.svg";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import logo from "../assets/images/logo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-footer">
        <div className="row-footer">
          <div className="footer-col">
            <h4>company</h4>
            <ul>
              <li>
                <Link to={"#"}>about us</Link>
              </li>
              <li>
                <Link to={"#"}>our services</Link>
              </li>
              <li>
                <Link to={"/privacy-policy"}>privacy policy</Link>
              </li>
              <li>
                <Link to={"/term-of-service"}>Terms of service</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>get help</h4>
            <ul>
              <li>
                <Link to={"#"}>FAQ</Link>
              </li>
              <li>
                <Link to={"#"}>shipping</Link>
              </li>
              <li>
                <Link to={"#"}>returns</Link>
              </li>
              <li>
                <Link to={"#"}>order status</Link>
              </li>
              <li>
                <Link to={"#"}>payment options</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>More Links</h4>
            <ul>
              <li>
                <Link to={"#"}>Link</Link>
              </li>
              <li>
                <Link to={"#"}>Link</Link>
              </li>
              <li>
                <Link to={"#"}>Link</Link>
              </li>
              <li>
                <Link to={"#"}>dress</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>follow us</h4>
            <div className="social-links">
              <Link to={"#"}>
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link to={"#"}>
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to={"#"}>
                <i className="fab fa-instagram"></i>
              </Link>
              <Link to={"#"}>
                <i className="fab fa-linkedin-in"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
