import InputField from "../InputField";

const questionValidation = {
  required: {
    value: true,
    message: "required",
  },
};

const RequestSellerCard = ({ style }) => {
  return (
    <div style={style} className="address-card">
      <h3>Request to become seller</h3>
      <div>
        <InputField
          type={"text"}
          placeholder={""}
          name={"questionOne"}
          id={"address"}
          validationRules={questionValidation}
          label={"hi"}
          multiline
        />
        <InputField
          type={"text"}
          placeholder={""}
          name={"questionTwo"}
          id={"city"}
          validationRules={questionValidation}
          label={"City"}
          multiline
        />
        <InputField
          type={"text"}
          placeholder={""}
          name={"questionThree"}
          id={"city"}
          validationRules={questionValidation}
          label={"City"}
          multiline
        />
      </div>
    </div>
  );
};

export default RequestSellerCard;
