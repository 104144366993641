import ReactDOM from "react-dom";
import "../../styles/formModalStyles.css";
import AuctionForm from "./AuctionForm";
import { useState } from "react";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../config/firebaseSetup";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

const EditAuction = ({ onClose, editAuction, getAllAuctions, id }) => {
  const [edit, setEdit] = useState(true);
  const [showDraftEdit, setShowDraftEdit] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const currentUser = auth.currentUser;

  const defaultValues = {
    auctionReservePrice: editAuction.auctionReservePrice,
    auctionTitle: editAuction.auctionTitle,
    auctionTitleItalian: editAuction.auctionTitleItalian,
    descriptionEnglish: editAuction.descriptionEnglish,
    descriptionItalian: editAuction.descriptionItalian,
    endDate: editAuction.endDate,
    images: editAuction.images,
    startDate: editAuction.startDate,
    tags: editAuction.tags,
    auctionStartingPrice: editAuction.auctionStartingPrice,
    startTime: editAuction.startTime,
    endTime: editAuction.endTime,
    category: editAuction.category,
    subcategory: editAuction.subcategory,
    tag: editAuction.tag,
  };

  const handleSubmit = async (
    formData,
    methods,
    setSelectedFile,
    setTags,
    handleCancelDelete
  ) => {
    // console.log(formData);
    try {
      const endDateTime = new Date(`${formData.endDate}T${formData.endTime}`);
      const startDateTime = new Date(
        `${formData.startDate}T${formData.startTime}`
      );
      const endDateAndTime = Timestamp.fromDate(endDateTime);
      const startDateAndTime = Timestamp.fromDate(startDateTime);

      const auctionDocRef = doc(db, "Auction", editAuction.id);
      await updateDoc(auctionDocRef, {
        ...formData,
        status: "pending",
        userEmail: currentUser.email,
        tag: editAuction.tag,
        serverEndTimeForAuction: endDateAndTime,
        serverStartTimeForAuction: startDateAndTime,
      });
      toast.success("Auction updated successfully!");
      getAllAuctions();
      setShowLoader(false);
      onClose();
    } catch (error) {
      setShowLoader(false);
      console.log(error);
      toast.error("Error updating auction:");
    }
  };

  const updateDraft = async (formData) => {
    setShowLoader(true);
    console.log(formData);
    try {
      // Reference to the "Auction" collection
      const auctionDocRef = doc(db, "Auction", id);

      // Check if the document exists before updating or adding it
      const docSnapshot = await getDoc(auctionDocRef);

      if (docSnapshot.exists()) {
        // Update the existing document
        // console.log("yes");
        await updateDoc(auctionDocRef, {
          ...formData,
          subcategory: formData.subcategory ? formData.subcategory : "",
          tags: formData.tags ? formData.tags : [],
          updated: serverTimestamp(),
        });
        toast.success("Auction Updated Successfully");
      }

      setShowLoader(false);
      if (getAllAuctions) {
        getAllAuctions();
      }

      // console.log("Document written/updated with ID:", docId);
    } catch (error) {
      console.error("Error updating/adding document: ", error);
      // console.log(draftData);

      toast.error("Error Drafting/Updating Auctions");
      setShowLoader(false);
      // onCancel();
    }
  };

  const handleDraft = async (draftData) => {
    setShowLoader(true);
    try {
      // Reference to the "Auction" collection
      const auctionsColRef = collection(db, "Auction");
      const currentUser = auth.currentUser;
      // Create a new document with the entire draftData
      const docRef = await addDoc(auctionsColRef, {
        created: serverTimestamp(),
        uid: currentUser.uid,
        status: "draft",
        ...draftData,
        tags: draftData.tags ? draftData.tags : [],
      });
      // console.log(draftData);
      toast.success("Auction Drafted Successfully");
      setShowLoader(false);
      if (getAllAuctions) {
        getAllAuctions();
      }

      // console.log("Document written with ID:", docRef.id);
    } catch (error) {
      // console.error("Error adding document: ", error);
      // console.log(draftData);

      toast.error("Error Drafting Auctions");
      setShowLoader(false);
      // onCancel();
    }
  };

  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="formModal-container"
    >
      <div className="formModal-content">
        <h2>Edit Your Auction</h2>
        <button onClick={onClose} className="cancel-auction-creation">
          Close
        </button>
      </div>
      <AuctionForm
        handleSubmit={handleSubmit}
        defaultValues={defaultValues}
        onCancel={onClose}
        edit={edit}
        duplicate={false}
        showDraftEdit={showDraftEdit}
        showLoader={showLoader}
        setShowLoader={setShowLoader}
        updateDraft={updateDraft}
        handleDraft={handleDraft}
        auctionStatus={editAuction.status}
      />
    </motion.div>,
    document.querySelector("#auctionEditFormModal")
  );
};

export default EditAuction;
