import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider as AuctionProvider } from "./context/AuctionContext";
import "./index.css";
import { UserProvider } from "./context/UserContext";
import { Provider as SidebarIndexProvider } from './context/SidebarIndex';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthProvider from "./AuthProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <UserProvider>
        <AuctionProvider>
          <SidebarIndexProvider>
            <Routes>
              <Route path="/*" element={
                <AuthProvider>
                  <App />
                </AuthProvider>} />
            </Routes>
          </SidebarIndexProvider>
        </AuctionProvider>
      </UserProvider>
    </Router>
  </React.StrictMode>
);
