import { FormProvider, useForm } from "react-hook-form";
import { commentValidation, tagValidation } from "../../utils/inputValidations";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Tooltip from "../Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { auth, db } from "../../config/firebaseSetup";
import { useTranslation } from "react-i18next";
import fetchAndSendEmailTemplate from "../../utils/emailSend";
const EXPIRATION_KEY = "tagCooldownExpiration";

const RenderInput = ({ validation, value, setValue, methods, t }) => {
  const {
    name,
    label,
    type,
    id,
    validationRules,
    placeholder,
    multiline,
    icon,
  } = validation;
  const { register, formState, trigger } = methods;
  const inputErrors = formState.errors[name] || [];
  // const isInvalid = inputErrors.length > 0;

  const toUpperCase = (e) => {
    setValue(e.target.value.toUpperCase());
    trigger(name);
  };

  return (
    <div className="auction-form-input">
      <label htmlFor={name}>{t(`${name}`)}</label>
      {multiline ? (
        <textarea
          type={type}
          id={id}
          name={name}
          placeholder={t(placeholder, { ns: "placeholder" })}
          {...register(name, validationRules)}
        ></textarea>
      ) : (
        <div className="icon-input-contain">
          {icon && (
            <div className="iicd">
              {icon && <FontAwesomeIcon icon={icon} className="icon-input-c" />}
            </div>
          )}
          <input
            type={type}
            id={id}
            name={name}
            value={value}
            style={{ paddingLeft: `${icon ? "3rem" : ""}` }}
            placeholder={t(placeholder, { ns: "placeholder" })}
            // {...register(name, validationRules)}
            {...register(name, {
              ...validationRules,
              onChange: toUpperCase,
            })}
          />
        </div>
      )}
      {inputErrors.message && (
        <div className="inputErrors">
          {t(inputErrors?.message, { ns: "errors" })}
        </div>
      )}
    </div>
  );
};

const RequestTagsForm = ({ userData }) => {
  const methods = useForm();
  const [isCooldown, setIsCooldown] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [value, setValue] = useState("");

  const startCooldown = () => {
    const currentTime = new Date().getTime();
    const expirationTime = currentTime + 2400000; // 40 minutes in milliseconds

    localStorage.setItem(EXPIRATION_KEY, expirationTime);
    setIsCooldown(true);

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const timeLeft = expirationTime - now;

      if (timeLeft <= 0) {
        setIsCooldown(false);
        clearInterval(timer);
        localStorage.removeItem(EXPIRATION_KEY);
      } else {
        setRemainingTime(timeLeft);
      }
    }, 1000);
  };

  useEffect(() => {
    const expirationTime = localStorage.getItem(EXPIRATION_KEY);
    const currentTime = new Date().getTime();

    if (expirationTime && parseInt(expirationTime) > currentTime) {
      const timeLeft = parseInt(expirationTime) - currentTime;
      setIsCooldown(true);
      setRemainingTime(timeLeft);
    }
  }, []);

  const currentUser = auth.currentUser;

  const handleSubmit = methods.handleSubmit(async (formData) => {
    try {
      const existingTagsQuery = query(
        collection(db, "tags"),
        where("name", "==", formData.tagInput.toUpperCase())
      );

      const existingTagsSnapshot = await getDocs(existingTagsQuery);

      if (!existingTagsSnapshot.empty) {
        // Tag already exists, inform the user and prevent submission
        toast.error("Tag already exists. Please try a different tag.");
        return;
      }

      // If tag doesn't exist, proceed with submitting the new tag
      const tagsColRef = collection(db, "tags");

      const docRef = await addDoc(tagsColRef, {
        created: serverTimestamp(),
        name: formData.tagInput.toUpperCase(),
        tagComment: formData.commentTag,
        uid: currentUser.uid,
        status: "pending",
        email: currentUser.email,
      });

      setIsCooldown(true);
      startCooldown();
      toast.success("Tag submitted");
      setValue("");
      methods.reset();

      if (
        userData &&
        userData.notificationSettings.tagRequestSubmitNotifications
      ) {
        fetchAndSendEmailTemplate(
          "For Tag Request",
          currentUser,
          "https://stripe-server-production-3c2c.up.railway.app/send-email"
        );
      }

      setTimeout(() => {
        setIsCooldown(false);
      }, 1000);
    } catch (error) {
      setIsCooldown(false);
      toast.error("An Error Occurred During Submitting Your Tag");
    }
  });

  useEffect(() => {
    return () => clearTimeout();
  }, []);

  const { t } = useTranslation("auctionForm");

  if (!currentUser) return <span>No USER</span>;

  return (
    <div className="auction-from">
      <div>
        <h2
          style={{
            margin: "1rem 0",
            fontSize: "1.3rem",
          }}
        >
          {t("reqHeadTag")}
        </h2>
      </div>
      <div className="bid-now-info">
        <p>{t("tagInfo")}</p>
      </div>
      <div className="bid-now-info" style={{ backgroundColor: "#ff8fa3" }}>
        <p>{t("tagWarn")}</p>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={(e) => e.preventDefault()}>
          <div>
            {
              <RenderInput
                validation={tagValidation}
                value={value}
                setValue={setValue}
                methods={methods}
                t={t}
              />
            }
          </div>
          <div>
            <div>
              {
                <RenderInput
                  validation={commentValidation}
                  methods={methods}
                  t={t}
                />
              }
            </div>
          </div>
          <div className="auction-form-buttons">
            <button
              type="submit"
              className="submit-auction"
              onClick={handleSubmit}
              disabled={isCooldown}
            >
              {isCooldown ? "Cool down" : "Submit Request"}
            </button>
            {/* <Tooltip
              text={
                "When your request is submitted your will have to wait 40 min, to submit a new request"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip> */}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default RequestTagsForm;
